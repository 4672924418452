import styled from "styled-components";

import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

export const OFFSET_LEFT_BTN_GR = "49px";
export const OFFSET_LEFT = "52px";
export const CONT_WIDTH = "72%";

export const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  overflow: auto;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  padding: ${({ $padding, $paddingTop }) =>
    $padding || `${$paddingTop || "28px"} ${OFFSET_LEFT_BTN_GR} 0`};
`;

export const Relative = styled.div`
  position: relative;
`;

export const Divider = styled.div`
  width: 12px;
  height: 2px;
  min-height: 2px;
  border-radius: 10px;
  background-color: #858585;
  margin: 5px 56px 15px;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => $width || "100%"};
  @media (max-width: 1000px) {
    width: 100%;
  }
  ${({ $inline }) =>
    $inline ? "flex-grow: 1; width: auto; margin-left: 21px;" : ""}
`;

export const Input = styled.input`
  &,
  &:focus-visible {
    border: 0.59px solid #cdcdcd;
    border-radius: 6px;
    box-shadow: 0 2.36px 5.89px 0 #0000000d, 0 0 5.89px 0 #0000000d inset;
    height: auto;
    width: 100%;
    margin: 0;
    padding: 9px 19px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.54px;
    color: #000000;
    background: #ffffff;
    outline: none;
  }

  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }

  + img.divider-vert {
    position: absolute;
    left: 14px;
    top: calc((100% - 19px) / 2);
    height: 19px;
    width: 0.59px;
    opacity: 0.1;
  }

  &:hover {
    border-color: #bfbfbf;
  }

  &:focus-visible {
    border-color: #ababab;
    + img.divider-vert {
      opacity: 0.2;
    }
  }
`;

export const ErrorIcon = styled.img.attrs({
  src: "/images/input-error-icon.svg",
  alt: "error",
})`
  position: absolute;
  right: 12px;
  top: calc((100% - 17px) / 2);
  height: 17px;
`;

export const ErrorMessage = styled.span`
  margin-top: 9px;
  ${({ $marginLeft }) => !!$marginLeft && `margin-left: ${$marginLeft};`}
  color: #de6363;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  width: 90%;
`;

export const RadioButtonGroup = styled.div`
  padding: 0 ${OFFSET_LEFT};
`;

export const RadioBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  width: ${CONT_WIDTH};
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const RadioInput = styled.div`
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 21px;

  span {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: ${({ $checked }) => ($checked ? "700" : "400")};
    color: ${({ $checked }) => ($checked ? "#000" : "#5A5A5A")};
  }

  > div {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 50%;
    ${({ $checked }) =>
      $checked
        ? `
      background-image: url("images/checked-radio-btn-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    `
        : `
      border: 1px solid #cdcdcd;
      background: #FFFFFF;
      box-shadow: 0 4px 10px 0 #0000000D, 0 0 10px 0 #0000000D inset;
    `};
  }

  &:hover {
    > div {
      border-color: #bfbfbf;
    }
  }
`;

export const InputTextareaBlock = styled.div`
  margin-top: ${({ $marginTop }) => $marginTop || "15px"};
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;

  .ql-editor.ql-blank::before {
    ${({ $haxHint, $isOpenedHint }) =>
      !$haxHint
        ? ""
        : !$isOpenedHint
        ? `
            display: none !important;
          `
        : `
            padding: 9px 6% calc(9px + 29px + 5px) 13px !important;
            width: 76% !important;
            color: #00000080 !important;
            background-color: #fcf6e4 !important;
            background-image: url(images/hint-bg.svg) !important;
            background-repeat: no-repeat !important;
            background-position: 13px bottom !important;
            background-size: 194px 29px !important;
            border-radius: 11px !important;
            box-shadow: 0 17px #fcf6e4 !important;
            font-style: normal !important;             
            font-family: "Poppins", sans-serif !important;
            font-size: 11px !important;
            font-weight: 400 !important;
            line-height: 16.28px !important;
        `}
  }
`;

export const InputTextarea = styled(ReactQuill)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #95959580;

  .ql-toolbar.ql-snow {
    margin: 7px;
    border-radius: 5px;
    border: 0;
    background: #d9d9d980;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 16.28px;
    color: #000000;
  }

  .ql-container.ql-snow {
    border-radius: 0 0 5px 5px;
    border: 0;
    min-height: fit-content;
    overflow-y: auto;
    * {
      font-family: "Poppins", sans-serif;
      font-size: 11px;
      line-height: 16.28px;
    }
    .ql-editor {
      padding: 4px 12px 12px;
      border-radius: 0 0 5px 5px;
      &.ql-blank {
        &::before {
          font-weight: 400;
          font-style: normal;
          color: #00000054;
        }
      }
    }
  }

  ${({ $hide }) => ($hide ? "display: none;" : "")}
`;
