import {
  fetchGetMegaPageData,
  fetchCreateMegaPageData,
  fetchUpdateMegaPageData,
  fetchDeleteMegaPageData,
  fetchMegaPages,
  fetchRegenerateMegaPageData,
} from "./queries.js";

const getMegaPageData = async (filters) => {
  try {
    const result = await fetchGetMegaPageData(filters);

    if (result.message) {
      return result;
    }

    return { success: true, megaPageData: result.seoData };
  } catch (error) {
    return { success: false, message: "Get Mega Page data failed" };
  }
};

const createMegaPageData = async (data) => {
  try {
    const result = await fetchCreateMegaPageData(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Create Mega Page data failed" };
  }
};

const updateMegaPageData = async (id, data) => {
  try {
    const result = await fetchUpdateMegaPageData(id, data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Update Mega Page data failed" };
  }
};

const regenerateMegaPageData = async (id) => {
  try {
    const result = await fetchRegenerateMegaPageData(id);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Regenerate Mega Page data failed" };
  }
};

const deleteMegaPageData = async (data) => {
  try {
    const result = await fetchDeleteMegaPageData(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Delete Mega Page data failed" };
  }
};

const getMegaPages = async () => {
  try {
    const result = await fetchMegaPages();

    if (result.message) {
      return result;
    }

    return { success: true, megaPages: result.megaPages };
  } catch (error) {
    return { success: false, message: "Get Mega Pages failed" };
  }
};

export {
  getMegaPageData,
  createMegaPageData,
  updateMegaPageData,
  regenerateMegaPageData,
  deleteMegaPageData,
  getMegaPages,
};
