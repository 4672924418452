import QuestionFormModal from "./QuestionFormModal";

const QuestionModalContent = ({
  state,
  questionModal,
  setState,
  handleSaveMegaPageData,
}) => {
  if (!state.selectedData || !questionModal.isOpen) return null;

  return (
    <QuestionFormModal
      megaPageData={state.selectedData}
      megaPages={state.megaPages}
      onClose={() => {
        questionModal.close();
        setState((prevState) => ({ ...prevState, selectedData: null }));
      }}
      onSave={(data) => {
        questionModal.close();
        handleSaveMegaPageData(data);
      }}
    />
  );
};

export default QuestionModalContent;
