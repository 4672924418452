import React from "react";

import TabList from "./Tabs";

import { Container, Title } from "./styles";

function Sidebar({ children }) {
  return (
    <Container>
      <Title>All Settings</Title>
      <TabList />
      {children}
    </Container>
  );
}

export default Sidebar;
