import React from "react";

import { TRANSACTIONAL_EMAIL_LABELS } from "../../../../../../utils/constants";
import { displayDate } from "../../../../../../utils/utils";

import theme from "../../../../../../theme";
import { TableRow, TableData } from "./styles";

const Row = ({ content }) => {
  const { type, sentOn, readOn } = content;

  return (
    <TableRow $borderBottom={`1px solid ${theme.colors.divider_color}`}>
      <TableData>{TRANSACTIONAL_EMAIL_LABELS[type]}</TableData>
      <TableData>{displayDate(sentOn)}</TableData>
      <TableData>{displayDate(readOn)}</TableData>
    </TableRow>
  );
};

export default Row;
