import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "styled-components";

import AdminApp from "./AdminApp";
import MainApp from "./MainApp";

import AuthProviders from "./providers/AuthProviders";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "./api/localStorage";
import { sendMessageToExtension } from "./utils/postToExtension";
import { clearCache } from "./utils/clearCache";
import packageJSON from "../package.json";
import { gtmId, isNeedToClearCache } from "./utils/config";
import { isAdminSite } from "./utils/environment";
import theme from "./theme";

!!gtmId && TagManager.initialize({ gtmId });

const App = () => {
  const isAdminEnvironment = isAdminSite();

  useEffect(() => {
    const checkVersion = () => {
      if (!isNeedToClearCache) {
        return;
      }
      const savedVersion = getDataFromLocalStorage("version");
      const currentVersion = packageJSON.version;

      if (savedVersion !== currentVersion) {
        clearCache();
        saveDataToLocalStorage("version", currentVersion);
        return;
      }
    };

    checkVersion();

    sendMessageToExtension({
      message: "get-storage-data",
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProviders>
        <Router>{isAdminEnvironment ? <AdminApp /> : <MainApp />}</Router>
      </AuthProviders>
    </ThemeProvider>
  );
};

export default App;
