import { styled } from "styled-components";

export const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 20px 0 0;
`;

export const Content = styled.div`
  height: calc(100% - 55px - 120px);
`;

export const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const NewQuestionButton = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  color: ${({ theme }) => theme.colors.white};
  height: 40px;
  padding: 0 25px;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 25px;

  &:disabled {
    pointer-events: none;
    opacity: 0.75;
  }
`;

export const Filters = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-right: 20px;
  z-index: 100;
`;

export const Select = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  > div:first-child {
    margin-bottom: 0;
    height: 40px;
    > div:first-child {
      height: 40px;
      > div:first-child {
        font-size: 13px;
        ${({ $noSelected }) => ($noSelected ? "color: #757678;" : "")}
      }
    }
  }
`;
