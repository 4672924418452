import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

import { Login } from "./pages";
import Admin from "./pages/Settings/Admin";

import CustomRoutes from "./CustomRoutes";

import { LOGIN_PAGE_CONTENT_LIST } from "./utils/constants";
import { saveDataToLocalStorage } from "./api/localStorage";

const AdminApp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const PATHS = {
    LOGIN: "/login",
    ROOT: "/",
  };

  const postMessageEventHandler = (event) => {
    if (!event?.data?.iframeStorageData) {
      return;
    }

    for (const key in event.data.iframeStorageData) {
      saveDataToLocalStorage(key, event.data.iframeStorageData[key], true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener("message", postMessageEventHandler);

    return () => {
      window.removeEventListener("message", postMessageEventHandler);
    };
  }, []);

  return (
    <>
      {!isLoading && (
        <CustomRoutes>
          <Route
            path={PATHS.LOGIN}
            element={
              <Login
                loginPageContent={LOGIN_PAGE_CONTENT_LIST.LOGIN}
                adminRedirectPath={PATHS.ROOT}
              />
            }
          />
          <Route
            path={PATHS.ROOT}
            element={<Admin loginPath={PATHS.LOGIN} />}
          />
        </CustomRoutes>
      )}
    </>
  );
};

export default AdminApp;
