import React from "react";

import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import ImageUploader from "./ImageUploader";
import Editor from "../Editor";
import ConnectBtn from "../../../../ConnectBtn";
import { getBaseUrl } from "../utils";
import { useFormState } from "./useFormState";

import "react-datepicker/dist/react-datepicker.css";
import {
  CloseIcon,
  Container,
  Content,
  Form,
  Inputs,
  InputBlock,
  Label,
  Actions,
  Relative,
  Input,
  ErrorIcon,
  ErrorMessage,
  LinkWrapper,
  BaseUrl,
  LinkContainer,
  EditorContainer,
  EditorBlock,
  EditorRelative,
  DateRange,
  RelatedArticlesContainer,
  FormContent,
  CheckboxBlock,
  CheckboxLabel,
} from "./styles";

function BlogPostFormPopup({ allBlogPosts, blogPost, onSave, onClose }) {
  const {
    formState,
    handleEventChange,
    handleTextChange,
    handleDateChange,
    handleArticlesChange,
    handleLoadProfilePicture,
    handleLoadHeroImage,
    handleFeaturedChange,
    setErrorMessages,
    validateForm,
    getFormData,
  } = useFormState(blogPost, allBlogPosts);

  const { formData, relatedArticleOptions, errorMessages } = formState;
  const {
    title,
    subTitle,
    writer,
    profilePicture,
    tag,
    publishDate,
    relatedArticles,
    heroImage,
    text,
    link,
    isFeatured,
  } = formData;

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
    } else {
      const formData = getFormData();

      onSave(formData);
    }
  };

  return (
    <Container>
      <Content>
        <CloseIcon className="close-icon" onClick={onClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>
        <FormContent>
          <Form>
            <Inputs>
              <InputBlock>
                <Label htmlFor="title">Title</Label>
                <Relative>
                  <Input
                    id="title"
                    name="title"
                    placeholder="Enter Title..."
                    value={title}
                    onChange={(event) => {
                      handleEventChange(event);
                      setErrorMessages({ title: "" });
                    }}
                    autoComplete="off"
                  />
                  {!!errorMessages.title && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.title && (
                  <ErrorMessage>{errorMessages.title}</ErrorMessage>
                )}
              </InputBlock>
              <InputBlock>
                <Label htmlFor="subTitle">Sub-Title</Label>
                <Relative>
                  <Input
                    id="subTitle"
                    name="subTitle"
                    placeholder="Enter Sub-Title..."
                    value={subTitle}
                    onChange={(event) => {
                      handleEventChange(event);
                      setErrorMessages({ subTitle: "" });
                    }}
                    autoComplete="off"
                  />
                  {!!errorMessages.subTitle && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.subTitle && (
                  <ErrorMessage>{errorMessages.subTitle}</ErrorMessage>
                )}
              </InputBlock>
              <InputBlock>
                <Label htmlFor="writer">Writer</Label>
                <Relative>
                  <Input
                    id="writer"
                    name="writer"
                    placeholder="Enter Writer..."
                    value={writer}
                    onChange={(event) => {
                      handleEventChange(event);
                      setErrorMessages({ writer: "" });
                    }}
                    autoComplete="off"
                  />
                  {!!errorMessages.writer && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.writer && (
                  <ErrorMessage>{errorMessages.writer}</ErrorMessage>
                )}
              </InputBlock>
              <InputBlock>
                <Label htmlFor="profilePicture">Profile Picture</Label>
                <Relative>
                  <ImageUploader
                    handleLoadFile={(file) => {
                      handleLoadProfilePicture(file);
                      setErrorMessages({ profilePicture: "" });
                    }}
                    image={profilePicture}
                  />
                  {!!errorMessages.profilePicture && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.profilePicture && (
                  <ErrorMessage>{errorMessages.profilePicture}</ErrorMessage>
                )}
              </InputBlock>
              <InputBlock>
                <Label htmlFor="tag">Tag</Label>
                <Relative>
                  <Input
                    id="tag"
                    name="tag"
                    placeholder="Enter Tag..."
                    value={tag}
                    onChange={(event) => {
                      handleEventChange(event);
                      setErrorMessages({ tag: "" });
                    }}
                    autoComplete="off"
                  />
                  {!!errorMessages.tag && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.tag && (
                  <ErrorMessage>{errorMessages.tag}</ErrorMessage>
                )}
              </InputBlock>
              <InputBlock>
                <Label htmlFor="publishDate">Publish Date</Label>
                <Relative>
                  <DateRange>
                    <DatePicker
                      placeholderText="MM/dd/yyyy"
                      dateFormat="MM/dd/yyyy"
                      selected={publishDate ? new Date(publishDate) : null}
                      onChange={(date) => {
                        handleDateChange(date);
                        setErrorMessages({ publishDate: "" });
                      }}
                      minDate={new Date()}
                      todayButton="Today"
                    />
                  </DateRange>
                  {!!errorMessages.publishDate && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.publishDate && (
                  <ErrorMessage>{errorMessages.publishDate}</ErrorMessage>
                )}
              </InputBlock>
              <RelatedArticlesContainer>
                <InputBlock>
                  <Label htmlFor="relatedArticles">Related Articles</Label>
                  <Relative>
                    <MultiSelect
                      options={relatedArticleOptions}
                      value={relatedArticleOptions.filter((article) =>
                        relatedArticles.includes(article.value)
                      )}
                      onChange={handleArticlesChange}
                      labelledBy="Related Articles"
                      hasSelectAll={false}
                      disableSearch={true}
                      closeOnChangedValue={true}
                      display="chip"
                    />
                  </Relative>
                </InputBlock>
              </RelatedArticlesContainer>
              <InputBlock>
                <Label htmlFor="heroImage">Hero Image</Label>
                <Relative>
                  <ImageUploader
                    handleLoadFile={(file) => {
                      handleLoadHeroImage(file);
                      setErrorMessages({ heroImage: "" });
                    }}
                    image={heroImage}
                  />
                  {!!errorMessages.heroImage && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {!!errorMessages.heroImage && (
                  <ErrorMessage>{errorMessages.heroImage}</ErrorMessage>
                )}
              </InputBlock>

              <InputBlock>
                <Relative>
                  <Label>Featured</Label>
                  <CheckboxBlock>
                    <input
                      type="checkbox"
                      id="isFeatured"
                      name="isFeatured"
                      checked={isFeatured}
                      onChange={(event) => {
                        handleFeaturedChange(event);
                      }}
                    />
                    <label htmlFor="isFeatured" className="toggle"></label>
                    <CheckboxLabel htmlFor="isFeatured">
                      Make this the featured post.
                    </CheckboxLabel>
                  </CheckboxBlock>
                </Relative>
              </InputBlock>

              <EditorContainer>
                <EditorBlock>
                  <Label htmlFor="text">Post</Label>
                  <EditorRelative>
                    <Editor
                      text={text}
                      handleChange={handleTextChange}
                      handleKeyUp={() => setErrorMessages({ text: "" })}
                    />
                    {!!errorMessages.text && (
                      <ErrorIcon
                        src="/images/input-error-icon.svg"
                        alt="error"
                      />
                    )}
                  </EditorRelative>
                  {!!errorMessages.text && (
                    <ErrorMessage>{errorMessages.text}</ErrorMessage>
                  )}
                </EditorBlock>
              </EditorContainer>
              <LinkContainer>
                <InputBlock>
                  <Label htmlFor="link">Link</Label>
                  <Relative>
                    <LinkWrapper>
                      <BaseUrl>{getBaseUrl()}/blog/</BaseUrl>
                      <Input
                        id="link"
                        name="link"
                        placeholder="Enter Link..."
                        value={link}
                        onChange={(event) => {
                          handleEventChange(event);
                          setErrorMessages({ link: "" });
                        }}
                        autoComplete="off"
                      />
                      {!!errorMessages.link && (
                        <ErrorIcon
                          src="/images/input-error-icon.svg"
                          alt="error"
                        />
                      )}
                    </LinkWrapper>
                  </Relative>
                  {!!errorMessages.link && (
                    <ErrorMessage>{errorMessages.link}</ErrorMessage>
                  )}
                </InputBlock>
              </LinkContainer>
            </Inputs>
          </Form>
        </FormContent>
        <Actions>
          <ConnectBtn
            onClick={handleSubmit}
            label={!blogPost.id ? "Create Post" : "Update Post"}
          />
        </Actions>
      </Content>
    </Container>
  );
}

export default BlogPostFormPopup;
