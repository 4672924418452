import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import CloseBtn from "../components/CloseBtn";
import { publicUrl } from "../utils/config";

const AuthLayout = ({
  isShowSwitchAuthPageSection = true,
  switchAuthPageText,
  switchAuthPageUrl,
  switchAuthPageBtn,
  children,
}) => {
  return (
    <main className="app">
      <CloseBtn />
      <Layout className="layout">
        <SidePanel>
          <LogoBlock>
            <Logo src="/images/logos/full-logo-white.png" alt="Logo" />
          </LogoBlock>
          <SidePanelContent>
            <SidePanelContentTitle>
              Brilliantly simple outreach campaigns
            </SidePanelContentTitle>
            <SidePanelContentDescription>
              Generate thousands of unique and personalized email and SMS
              messages in seconds directly from a spreadsheet or your CRM
            </SidePanelContentDescription>
            {isShowSwitchAuthPageSection && (
              <>
                <SidePanelContentDescription>
                  {switchAuthPageText}
                </SidePanelContentDescription>
                <Link to={switchAuthPageUrl}>
                  <SignUpBtn>{switchAuthPageBtn}</SignUpBtn>
                </Link>
              </>
            )}
          </SidePanelContent>
        </SidePanel>
        <Content>{children}</Content>
      </Layout>
    </main>
  );
};

export default AuthLayout;

const Layout = styled.div`
  background-color: #fff;
`;

const SidePanel = styled.div`
  width: 55%;
  background: no-repeat url(${publicUrl}/log_screens_bg.png);
  background-size: cover;
  border-right: 1px solid #cdcdcd;
  padding: 25px;
  overflow: auto;
`;

const LogoBlock = styled.div``;

const Logo = styled.img`
  width: 161px;
  margin: 34px 0 0 43px;
`;

const SidePanelContent = styled.div`
  margin: 91px 20% 0 105px;
`;

const SidePanelContentTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 40px;
  font-weight: 800;
  line-height: 36.4px;
  color: white;
  margin-bottom: 8px;

  @media (min-width: 2000px) {
    font-size: 50px;
    margin-bottom: 16px;
  }
`;

const SidePanelContentDescription = styled.div`
  color: white;
  margin-bottom: 24px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  font-weight: 400;
  line-height: 17.04px;
  & + & {
    margin-top: -5px;
  }

  @media (min-width: 2000px) {
    font-size: 15px;
  }
`;

const SignUpBtn = styled.button`
  border: 1px solid #d1d1d1;
  color: #cdcdcd;
  border-radius: 6px;
  height: 42px;
  padding: 10px 36px;
  background-color: transparent;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: center;
  white-space: nowrap;

  &:hover {
    border-color: white;
    color: white;
  }

  @media (min-width: 2000px) {
    font-size: 15px;
  }
`;

const Content = styled.div`
  width: 45%;
  background-color: white;
  padding: 55px 33px;
  display: flex;
  align-items: center;
`;
