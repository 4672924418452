const GOALS = {
  schedulePhoneCall: "Get contact to schedule a phone call",
  visitLink: "Get contact to visit a link",
  emailReply: "Any email reply",
};

const ALT_GOAL_NAMES = {
  [GOALS.schedulePhoneCall]: "Schedule a phone call",
  [GOALS.visitLink]: "Get contact to visit a link",
};

const VALIDATE_GOAL_GPT_ANSWERS = {
  custom: "Custom",
  emailReply: "Email Reply",
  link: "Link",
};

// Bullhorn campaign types from server
const BULLHORN_CAMPAIGN_TYPES = {
  recruitForJob: "Recruit for a job",
  marketToCandidates: "Market to candidates",
  marketToClientContacts: "Market to client contacts",
  marketToClientLeads: "Market to client leads",
};

// NationBuilder campaign types from server
const NATIONBUILDER_CAMPAIGN_TYPES = {
  promoteAnEvent: "Promote an event",
  askForDonations: "Ask for donations",
  engageWithConstituents: "Engage with constituents",
  turnOutTheVote: "Turn out the vote",
  runQualitativeAnalysis: "Run a qualitative analysis",
};
const NATIONBUILDER_CAMPAIGN_GOALS_FOR_TYPES = {
  [NATIONBUILDER_CAMPAIGN_TYPES.promoteAnEvent]: GOALS.visitLink,
  [NATIONBUILDER_CAMPAIGN_TYPES.askForDonations]: GOALS.visitLink,
  [NATIONBUILDER_CAMPAIGN_TYPES.engageWithConstituents]: GOALS.emailReply,
  [NATIONBUILDER_CAMPAIGN_TYPES.turnOutTheVote]: GOALS.emailReply,
  [NATIONBUILDER_CAMPAIGN_TYPES.runQualitativeAnalysis]: GOALS.emailReply,
};

const MESSAGE_TYPES = {
  email: "email",
  sms: "sms",
  emailAndSms: "emailAndSms",
};

const MESSAGE_TYPES_OPTIONS = [
  { value: MESSAGE_TYPES.email, label: "Email only" },
  { value: MESSAGE_TYPES.sms, label: "SMS only" },
  { value: MESSAGE_TYPES.emailAndSms, label: "Email and SMS" },
];

const MAX_FOLLOW_UPS = 3;

const getAttemptsOptions = () =>
  Array.from({ length: MAX_FOLLOW_UPS + 1 }, (_, index) => ({
    value: index,
    label: index ? String(index) : "None",
  }));

const ATTEMPTS_OPTIONS = getAttemptsOptions();

const DURATION_OPTIONS = [
  { label: "1 week", value: "1w" },
  { label: "2 weeks", value: "2w" },
  { label: "3 weeks", value: "3w" },
];

const DURATION_OPTIONS_FOR_ADMIN = [
  { label: "1 hour", value: "1h" },
  { label: "5 hours", value: "5h" },
  { label: "1 day", value: "1d" },
];

const BEGIN_OPTION_IMMEDIATELY = "Immediately";
const BEGIN_OPTION_DATE_SELECTION = "Date selection";

const BEGIN_OPTIONS = [
  { label: BEGIN_OPTION_IMMEDIATELY, value: BEGIN_OPTION_IMMEDIATELY },
  { label: BEGIN_OPTION_DATE_SELECTION, value: BEGIN_OPTION_DATE_SELECTION },
];

const getSmsTimingOptions = () =>
  Array.from({ length: MAX_FOLLOW_UPS }, (_, index) => {
    const value = index + 1;
    const suffix = getSuffix(value);

    return {
      value,
      label: `As ${value}${suffix} message`,
    };
  });

const getSuffix = (number) => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return "st";
  } else if (number % 10 === 2 && number % 100 !== 12) {
    return "nd";
  } else if (number % 10 === 3 && number % 100 !== 13) {
    return "rd";
  }
  return "th";
};

const SMS_TIMING_OPTIONS = getSmsTimingOptions();

const TONE_OPTIONS = [
  { label: "Casual", value: "casual" },
  { label: "Formal", value: "formal" },
  { label: "Corporate", value: "corporate" },
  { label: "Friendly", value: "friendly" },
  { label: "Humorous", value: "humorous" },
  { label: "Warm", value: "warm" },
  { label: "Witty", value: "witty" },
  { label: "Authentic", value: "authentic" },
  { label: "Slick", value: "slick" },
  { label: "Salesy", value: "salesy" },
  { label: "Direct", value: "direct" },
];

const OPTIONS_DEFINITIONS = [
  {
    title: "Follow-ups",
    definition:
      "Defines the number of follow-up messages Reach will send during this campaign.",
  },
  {
    title: "Duration",
    definition:
      "Defines how long your campaign will last. Reach will spread outreach attempts over the duration.",
  },

  {
    title: "AI Model",
    definition: `Select from a variety of models to best suit the campaign's needs. `,
  },
  {
    title: "Tone",
    definition:
      "Further personalize your messages by selecting a tone of your preference. ",
  },
  {
    title: "Begin",
    definition: `Specifies the start date of your campaign.  Select "Immediately" to launch right away. `,
  },
  {
    title: "Auto Reply",
    definition:
      "Setting auto-reply to true allows Reach to respond to messages on your behalf. ",
  },
  {
    title: "Additional Instructions",
    definition:
      "Additional instructions to the LLM when generating the messages (for example, “Keep the messages very short” or “Don’t mention the salary”). ",
  },
];

const FIRST_WIZARD_TAB_NUM = 1;

const AI_MODEL = {
  DEFAULT: "Default",
  BASIC: "Basic",
  GENIUS: "Genius",
};

const AI_MODEL_FIELDS_IN_COMPANY = {
  [AI_MODEL.DEFAULT]: "defaultModelId",
  [AI_MODEL.BASIC]: "regularModelId",
  [AI_MODEL.GENIUS]: "premiumModelId",
};

const LANGUAGE_ENGLISH_DEFAULT = "English";

const LANGUAGE_LIST = [
  LANGUAGE_ENGLISH_DEFAULT,
  "Spanish",
  "French",
  "German",
  "Italian",
  "Brazilian Portuguese",
  "European Portuguese",
  "Russian",
  "Hindi",
  "Dutch",
  "Chinese (Mandarin)",
];

const QUILL_SETTINGS_FOR_DETAILS = {
  theme: "snow",
  modules: {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        [{ align: [] }],
        [{ color: [] }],
        [
          "bold",
          "italic",
          "underline",
          "strike",
          "clean",
          { list: "bullet" },
          { list: "ordered" },
          "link",
          "code-block",
          "blockquote",
        ],
      ],
    },
    "table-better": {},
  },
};

const OTHER_DETAILS_PREVIEW_TEXT_TYPE = "other";

export {
  GOALS,
  ALT_GOAL_NAMES,
  VALIDATE_GOAL_GPT_ANSWERS,
  BULLHORN_CAMPAIGN_TYPES,
  NATIONBUILDER_CAMPAIGN_TYPES,
  NATIONBUILDER_CAMPAIGN_GOALS_FOR_TYPES,
  MESSAGE_TYPES,
  MESSAGE_TYPES_OPTIONS,
  ATTEMPTS_OPTIONS,
  DURATION_OPTIONS,
  DURATION_OPTIONS_FOR_ADMIN,
  BEGIN_OPTION_IMMEDIATELY,
  BEGIN_OPTION_DATE_SELECTION,
  BEGIN_OPTIONS,
  SMS_TIMING_OPTIONS,
  TONE_OPTIONS,
  FIRST_WIZARD_TAB_NUM,
  AI_MODEL,
  AI_MODEL_FIELDS_IN_COMPANY,
  OPTIONS_DEFINITIONS,
  LANGUAGE_ENGLISH_DEFAULT,
  LANGUAGE_LIST,
  QUILL_SETTINGS_FOR_DETAILS,
  OTHER_DETAILS_PREVIEW_TEXT_TYPE,
};
