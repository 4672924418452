import React from "react";

import { Button } from "./styles";

const LogoutButton = ({ handleClick }) => {
  return (
    <Button title="Log out" onClick={handleClick}>
      <img src="images/logout.svg" alt="logout" />
    </Button>
  );
};

export default LogoutButton;
