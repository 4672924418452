import { styled } from "styled-components";

export const Container = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const Content = styled.div`
  position: relative;
  width: ${({ $isAnswerExist }) => (!$isAnswerExist ? "70%" : "85%")};
  ${({ $isAnswerExist }) => (!$isAnswerExist ? "max-width: 500px" : "")};
  margin: 0 auto;
  ${({ $isAnswerExist }) => (!$isAnswerExist ? "height: fit-content" : "")};
  ${({ $isAnswerExist }) => (!$isAnswerExist ? "max-height: 70vh" : "")};
  ${({ $isAnswerExist }) => ($isAnswerExist ? "height: 85%" : "")};
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${({ $isAnswerExist }) => (!$isAnswerExist ? "width: 100%" : "")};
  ${({ $isAnswerExist }) =>
    !$isAnswerExist ? "max-height: calc(70vh - 80px);" : ""};
  ${({ $isAnswerExist }) => ($isAnswerExist ? "height: 100%" : "")};
`;

export const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Select = styled.div`
  width: ${({ $isAnswerExist }) => (!$isAnswerExist ? "90%" : "100%")};
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  > div:first-child {
    margin-bottom: 0;
    height: 40px;
    > div:first-child {
      height: 40px;
      > div:first-child {
        font-size: 13px;
        ${({ $noSelected }) => ($noSelected ? "color: #757678;" : "")}
      }
    }
  }
  + img {
    right: 16%;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;
export const Relative = styled.div`
  position: relative;
`;

export const EditorContainer = styled.div`
  height: calc(
    100% - 70px - 70px - 70px - 40px
  ); // input, select, input, action button
`;

export const EditorBlock = styled(InputBlock)`
  height: 100%;
`;

export const EditorRelative = styled(Relative)`
  height: 100%;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

export const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: ${({ $isAnswerExist }) => (!$isAnswerExist ? "90%" : "100%")};
  box-shadow: 0 0 10px #dfdfdf;

  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: ${({ $isAnswerExist }) => (!$isAnswerExist ? "90%" : "100%")};
  }
`;

export const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
  background: #fff;
`;

export const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

export const Actions = styled.div`
  display: flex;
  margin-left: 10px;
  ${({ $isAnswerExist }) => (!$isAnswerExist ? "padding-top: 20px" : "")};
  position: sticky;
  bottom: 0;
  background: #fff;
  ${({ $isAnswerExist }) => ($isAnswerExist ? "margin-top: auto" : "")};
`;
