import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import { getAccessToken } from "../../../../api/axios";
import { USER_ROLES } from "../../../../utils/constants";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import { removeLoginDataFromLocalStorage } from "../../../../api/localStorage";

export function useAuth(loginPath) {
  const navigate = useNavigate();
  const isAuth = !!getAccessToken();
  const userInfo = getUserFromLocalStorage();
  const isAdminUser = userInfo?.role === USER_ROLES.ADMIN;

  useEffect(() => {
    if (!isAuth || !isAdminUser) {
      navigate(loginPath);
    }
  }, [loginPath, navigate, isAuth, isAdminUser]);

  const handleLogout = useCallback(() => {
    sendMessageToExtension({
      message: "sign-out-admin",
    });
    removeLoginDataFromLocalStorage();
    navigate(loginPath);
  }, [loginPath, navigate]);

  return { userInfo, handleLogout };
}
