import React, { useState } from "react";

import Editor from "../../Editor";
import ConnectBtn from "../../../../../ConnectBtn";
import CustomSelect from "../../../../../CustomSelect";

import {
  Actions,
  CloseIcon,
  Container,
  Content,
  ErrorIcon,
  ErrorMessage,
  Form,
  Input,
  InputBlock,
  EditorContainer,
  Inputs,
  Label,
  Relative,
  Select,
  EditorBlock,
  EditorRelative,
} from "./styles";

function QuestionFormModal({ megaPageData, megaPages, onClose, onSave }) {
  const [topic, setTopic] = useState(megaPageData?.topic || "");
  const [question, setQuestion] = useState(megaPageData?.question || "");
  const [answer, setAnswer] = useState(megaPageData?.answer || "");
  const [megaPageId, setMegaPageId] = useState(megaPageData?.megaPageId || "");
  const [errorMessages, setErrorMessages] = useState({});
  const isEditMode = Boolean(megaPageData?.id);
  const isAnswerExist = Boolean(megaPageData?.answer) || Boolean(answer);

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
    } else {
      const formData = getFormData();

      onSave(formData);
    }
  };

  const getFormData = () => ({
    ...(megaPageData?.id ? { ...megaPageData } : {}),
    topic,
    question,
    answer,
    megaPageId,
  });

  const validateBaseFields = () => {
    const errors = {};

    if (!topic) errors.topic = "Missed topic";
    if (!question) errors.question = "Missed question";
    if (!megaPageId) errors.megaPageId = "Missed megaPageId";

    return errors;
  };

  const validateAnswerField = () => {
    if (!answer) return { answer: "Missed answer" };

    return {};
  };

  const validateForm = () => {
    let errors = validateBaseFields();

    if (isEditMode) {
      errors = { ...errors, ...validateAnswerField() };
    }

    return errors;
  };

  const handleChange = (value, _delta, _source, editor) => {
    const text = editor.getText().replace(/\s+/g, "").trim();

    if (!text) {
      setAnswer("");

      return;
    }

    setAnswer(value);
  };

  return (
    <Container>
      <Content $isAnswerExist={isAnswerExist}>
        <CloseIcon className="close-icon" onClick={onClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>

        <Form onSubmit={handleSubmit} $isAnswerExist={isAnswerExist}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="topic">Topic</Label>
              <Relative>
                <Input
                  id="topic"
                  name="topic"
                  value={topic}
                  onChange={(e) => {
                    setTopic(e.target.value);
                    setErrorMessages((m) => ({ ...m, topic: "" }));
                  }}
                  placeholder="Enter topic..."
                  autoComplete="off"
                  $isAnswerExist={isAnswerExist}
                />
                {!!errorMessages.topic && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.topic && (
                <ErrorMessage>{errorMessages.topic}</ErrorMessage>
              )}
            </InputBlock>

            <InputBlock>
              <Label>Page</Label>
              <Relative>
                <Select
                  $noSelected={!megaPageId}
                  $isAnswerExist={isAnswerExist}
                >
                  <CustomSelect
                    options={megaPages}
                    title="Select Page..."
                    width="100%"
                    selected={megaPageId}
                    onSelect={(value) => {
                      setMegaPageId(value);
                      setErrorMessages((m) => ({ ...m, megaPageId: "" }));
                    }}
                    isClearable
                  />
                </Select>
                {!!errorMessages.megaPageId && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.megaPageId && (
                <ErrorMessage>{errorMessages.megaPageId}</ErrorMessage>
              )}
            </InputBlock>

            <InputBlock>
              <Label htmlFor="question">Question</Label>
              <Relative>
                <Input
                  id="question"
                  name="question"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                    setErrorMessages((m) => ({ ...m, question: "" }));
                  }}
                  placeholder="Enter question..."
                  autoComplete="off"
                  $isAnswerExist={isAnswerExist}
                />
                {!!errorMessages.question && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.question && (
                <ErrorMessage>{errorMessages.question}</ErrorMessage>
              )}
            </InputBlock>

            {isAnswerExist && (
              <EditorContainer>
                <EditorBlock>
                  <Label htmlFor="answer">Answer</Label>
                  <EditorRelative>
                    <Editor
                      answer={answer}
                      handleChange={handleChange}
                      handleKeyUp={() => {
                        setErrorMessages((m) => ({ ...m, answer: "" }));
                      }}
                    />
                    {!!errorMessages.answer && (
                      <ErrorIcon
                        src="/images/input-error-icon.svg"
                        alt="error"
                      />
                    )}
                  </EditorRelative>
                  {!!errorMessages.answer && (
                    <ErrorMessage>{errorMessages.answer}</ErrorMessage>
                  )}
                </EditorBlock>
              </EditorContainer>
            )}
          </Inputs>

          <Actions $isAnswerExist={isAnswerExist}>
            <ConnectBtn
              type="submit"
              label={!megaPageData.id ? "Create Question" : "Update Question"}
            />
          </Actions>
        </Form>
      </Content>
    </Container>
  );
}

export default QuestionFormModal;
