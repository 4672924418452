import { useState, useEffect, useCallback } from "react";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import {
  createMegaPageData,
  getMegaPageData,
  updateMegaPageData,
  deleteMegaPageData,
  getMegaPages,
  regenerateMegaPageData,
} from "../../../../services/megaPage";

export function useStateData() {
  const [state, setState] = useState({
    isLoading: false,
    megaPageData: [],
    megaPages: [],
    selectedData: null,
    filters: {
      megaPageId: "",
    },
  });

  const setLoading = (isLoading) => {
    setState((prevState) => ({ ...prevState, isLoading }));
  };

  const saveMegaPageData = (updater) => {
    setState((prevState) => ({
      ...prevState,
      megaPageData: updater(prevState.megaPageData),
    }));
  };

  const handleError = useCallback((message, defaultMessage) => {
    setLoading(false);
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: message || defaultMessage,
      },
    });
  }, []);

  const fetchMegaPages = useCallback(async () => {
    setLoading(true);

    const result = await getMegaPages();

    if (!result?.success) {
      handleError(result.message, "Failed to fetch Mega Pages");
      return;
    }

    const megaPages = result.megaPages.map((item) => ({
      value: item.id,
      label: item.pageName,
    }));

    setState((prevState) => ({
      ...prevState,
      megaPages,
      filters: {
        ...prevState.filters,
        megaPageId: megaPages.length > 0 ? megaPages[0].value : "",
      },
    }));

    setLoading(false);
  }, [handleError]);

  useEffect(() => {
    fetchMegaPages();
  }, [fetchMegaPages]);

  const fetchMegaPageData = useCallback(async () => {
    setLoading(true);

    const result = await getMegaPageData(state.filters);

    if (!result?.success) {
      handleError(result.message, "Failed to fetch Mega Page data");
      return;
    }

    saveMegaPageData(() => [...result.megaPageData]);
    setLoading(false);
  }, [handleError, state.filters]);

  useEffect(() => {
    if (state.filters.megaPageId) {
      fetchMegaPageData();
    }
  }, [fetchMegaPageData, state.filters.megaPageId]);

  const handleCreateMegaPageData = async (data) => {
    setLoading(true);

    const result = await createMegaPageData(data);

    if (!result?.success) {
      handleError(result.message, "Mega Page was not created");
      return;
    }

    const { seoData } = result.result;

    if (seoData?.megaPageId === state.filters.megaPageId) {
      saveMegaPageData((prevState) => [...prevState, seoData]);
    }

    setLoading(false);
  };

  const handleUpdateMegaPageData = async (data) => {
    if (!data?.id) {
      handleError({}, "Invalid Mega Page data");
      return;
    }

    setLoading(true);

    const result = await updateMegaPageData(data.id, data);

    if (!result?.success) {
      handleError(result, "Mega Page was not updated");
      setLoading(false);
      return;
    }

    const { seoData } = result.result;

    saveMegaPageData((prevState) => {
      if (seoData.megaPageId !== state.filters.megaPageId) {
        return prevState.filter((item) => item.id !== seoData.id);
      }

      return prevState.map((item) =>
        item.id === seoData.id ? { ...item, ...seoData } : item
      );
    });

    setLoading(false);
  };

  const handleSaveMegaPageData = (data) => {
    setState((prevState) => ({ ...prevState, selectedData: null }));

    if (!data.id) {
      return handleCreateMegaPageData(data);
    }

    const selectedData = state.selectedData || {};

    const updatedFields = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (value !== selectedData[key]) {
          acc[key] = value;
        }
        return acc;
      },
      { id: data.id }
    );

    handleUpdateMegaPageData(updatedFields);
  };

  const handleRegenerateMegaPageData = async (event, id) => {
    event?.preventDefault();

    setLoading(true);

    const result = await regenerateMegaPageData(id);

    if (!result?.success) {
      handleError(result, "Mega Page was not regenerated");
      return;
    }

    const { seoData } = result.result;

    saveMegaPageData((prevState) =>
      prevState.map((item) => (item.id === id ? { ...item, ...seoData } : item))
    );

    setLoading(false);
  };

  const handleRemoveMegaPageData = async (event) => {
    event?.preventDefault();

    setLoading(true);

    const result = await deleteMegaPageData({ id: state.selectedData.id });

    if (!result?.success) {
      handleError(result.message, "Mega Page didn't remove");
      return;
    }

    saveMegaPageData((prevState) =>
      prevState.filter(
        (megaPageData) => megaPageData.id !== state.selectedData.id
      )
    );
    setState((prevState) => ({ ...prevState, selectedData: null }));
    setLoading(false);
  };

  return {
    state,
    setState,
    handleSaveMegaPageData,
    handleRegenerateMegaPageData,
    handleRemoveMegaPageData,
  };
}

export function useModal(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return { isOpen, open, close };
}
