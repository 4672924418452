import React from "react";

import { ADMIN_ENVs } from "../../../../utils/constants";

import { EnvName } from "../../../styles";

function EnvDisplay() {
  const currentEnv = () => {
    const envName = Object.keys(ADMIN_ENVs).find(
      (key) => ADMIN_ENVs[key] === window.location.origin
    );
    return envName;
  };

  return <EnvName>env: {currentEnv()}</EnvName>;
}

export default EnvDisplay;
