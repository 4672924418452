import { styled } from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 20px 0 0;
  position: relative;
`;

export const Content = styled.div`
  height: calc(100% - 55px - 60px);
`;

export const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Filters = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  .multi-select .dropdown-content {
    z-index: 11;
  }
`;

export const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-right: 20px;
  z-index: 100;
`;

export const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const ExportButton = styled.button`
  margin-left: auto;
  margin-top: auto;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  padding: 0 22px;
  height: 40px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #757678;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;
