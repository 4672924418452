import { styled } from "styled-components";

export const Container = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const Content = styled.div`
  position: relative;
  width: 85%;
  margin: 0 auto;
  height: 85%;
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  height: 90%;
  overflow: hidden;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

export const Relative = styled.div`
  position: relative;
`;

export const LinkContainer = styled.div`
  margin-top: auto;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BaseUrl = styled.span`
  font-size: 13.3px;
`;

export const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 100%;
  box-shadow: 0 0 10px #dfdfdf;

  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 100%;
  }
`;

export const EditorContainer = styled.div`
  height: 100%;
  min-height: 500px;
  margin-bottom: 40px;
`;

export const EditorBlock = styled(InputBlock)`
  height: 100%;
  min-height: 500px;

  img {
    top: 50%;
  }
`;

export const EditorRelative = styled(Relative)`
  height: calc(100% - 70px);
  min-height: 500px;
  margin-top: 5px;
`;

export const ErrorIcon = styled.img`
  position: absolute;
  right: 3%;
  top: 24%;
  background: #fff;
`;

export const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

export const Actions = styled.div`
  display: flex;
  margin-left: 10px;
  background: #fff;
  margin-top: auto;
`;

export const DateRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin-bottom: 5px;
  > .react-datepicker-wrapper {
    > .react-datepicker__input-container > input {
      margin: 0;
      width: 97px;
      border-radius: 4px;
      border-color: #ccc;
      box-shadow: none;
      font-size: 12px;
    }
  }
  .react-datepicker-popper {
    z-index: 11;
    .react-datepicker__month-container {
      font-size: 12px;
      .react-datepicker__current-month {
        font-size: 12px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        margin: 3px;
        width: 21px;
        line-height: 21px;
      }
    }
  }
`;

export const RelatedArticlesContainer = styled.div`
  margin-bottom: 5px;
`;

export const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

export const CheckboxLabel = styled.label`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
  cursor: pointer;
`;
