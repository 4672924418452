import { styled } from "styled-components";
import ReactQuill from "react-quill-new";

export const Container = styled(ReactQuill)`
  border: none;
  outline: none;
  resize: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .ql-container.ql-snow {
    height: 200px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .ql-editor ul {
    list-style-type: decimal;
  }

  .ql-editor ol {
    list-style-type: disc;
  }

  .ql-editor ol li:before {
    content: "";
  }
`;
