import React, { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";

import {
  DragDropArea,
  DragDropAreaText,
  UploadBlock,
  UploadedImage,
  UploaderContainer,
} from "./styles";

const ImageUploader = ({ handleLoadFile, image }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
      handleLoadFile(acceptedFiles[0]);
    },
    [handleLoadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    multiple: false,
  });

  return (
    <UploaderContainer>
      <UploadedImage $noImage={!(selectedFile || image)}>
        {(selectedFile || image) && (
          <img
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile)
                : `${image}${
                    image.startsWith("http") ? `?rnd=${Math.random()}` : ""
                  }`
            }
            alt="uploaded pic"
          />
        )}
      </UploadedImage>
      <DragDropArea {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <DragDropAreaText>Upload image...</DragDropAreaText>
        ) : (
          <>
            <DragDropAreaText>
              Drag and Drop your image here, or
            </DragDropAreaText>
            <UploadBlock>
              <div>Upload...</div>
            </UploadBlock>
          </>
        )}
      </DragDropArea>
    </UploaderContainer>
  );
};

export default ImageUploader;
