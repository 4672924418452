import React, { useRef, useState } from "react";
import StepButton from "../../../StepButton";
import { FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS } from "../../../../utils/constants";
import FirstTimeCampaignOnboarding from "../../../FirstTimeCampaignOnboarding";
import { ButtonGroup, Section } from "../../styles";
import SaveDraftButton from "../SaveDraftButton";
import CampaignType from "./CampaignType";

function CampaignTypeStep({
  setCampaignType,
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const parentRef = useRef(null);
  const [isCustomTypeInputOpened, setIsCustomTypeInputOpened] = useState(false);
  const [selectedType, setType] = useState(campaign.type);
  const [customType, setCustomType] = useState("");
  const [customTypeErrorMessage, setCustomTypeErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignType,
    parentRef,
  };

  const handleClickNext = (event) => {
    event.preventDefault();

    if (selectedType === "" && !isCustomTypeInputOpened && !customType) {
      setErrorMessage("Please choose a campaign type");
      return;
    }

    if (selectedType === "" && (isCustomTypeInputOpened || customType)) {
      if (!customType && isCustomTypeInputOpened) {
        setCustomTypeErrorMessage("This field is required");
        return;
      }
      if (customType) {
        setCustomTypeErrorMessage("");
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      type: !selectedType && customType ? customType : selectedType,
    }));
    handleIncreaseStep();
  };

  const applyCampaignChanges = (asDraft) => {
    setCampaign((campaign) => ({
      ...campaign,
      type: !selectedType && customType ? customType : selectedType,
      asDraft,
    }));
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    applyCampaignChanges();
    handleDecreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();
    applyCampaignChanges(true);
  };

  return (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <CampaignType
          {...{
            setCampaignType,
            campaign,
            isCustomTypeInputOpened,
            setIsCustomTypeInputOpened,
            selectedType,
            setType,
            customType,
            setCustomType,
            customTypeErrorMessage,
            setCustomTypeErrorMessage,
            errorMessage,
            setErrorMessage,
          }}
        />
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton onClick={handleClickNext} isNext={true} />
        <SaveDraftButton onClick={handleClickSaveDraft} />
      </ButtonGroup>
    </>
  );
}

export default CampaignTypeStep;
