import React, { useContext } from "react";

import Tab from "./Tab";
import { SettingsContext } from "../../context";
import SubTabGroup from "./SubTabGroup";

import { List, ListItem } from "./styles";

function TabList() {
  const { sidebarTabs, activeTabsPath, handleTabClick } =
    useContext(SettingsContext);

  const getDeepestFirstTab = (tabObject) => {
    let path = [];
    let currentTab = Object.keys(tabObject)[0];
    path.push(currentTab);

    while (tabObject[currentTab]?.tabs) {
      tabObject = tabObject[currentTab].tabs;
      currentTab = Object.keys(tabObject)[0];
      path.push(currentTab);
    }

    return path;
  };

  const buildFullPath = (tabName) => {
    const path = [tabName];

    if (sidebarTabs[tabName]?.tabs) {
      const deepestPath = getDeepestFirstTab(sidebarTabs[tabName].tabs);
      path.push(...deepestPath);
    }

    return path;
  };

  return (
    <List>
      {Object.keys(sidebarTabs).map((tabName) => {
        const subTabs = sidebarTabs[tabName]?.tabs;
        const hasSubTabs = subTabs && Object.keys(subTabs).length > 0;
        const fullPath = buildFullPath(tabName);
        const isActiveTab = activeTabsPath.includes(tabName);
        const showSubTabs = hasSubTabs && isActiveTab;

        return (
          <ListItem
            key={tabName}
            $isActiveTab={isActiveTab}
            $isActiveSubTab={showSubTabs}
          >
            <Tab
              tabName={tabName}
              title={sidebarTabs[tabName].title}
              handleClick={() => handleTabClick(tabName, fullPath)}
              isActiveTab={isActiveTab}
              styles={{
                lineHeight: "normal",
              }}
            />
            {showSubTabs && (
              <SubTabGroup
                tabs={subTabs}
                handleClick={handleTabClick}
                parentPath={[tabName]}
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

export default TabList;
