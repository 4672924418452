import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import CampaignWizard from "../components/CreateCampaign/CampaignWizard";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import { getTempCampaign, resetTempCampaign } from "../services/campaigns";
import {
  getWizardTabs,
  isNotHaveNonDefaultAiModel,
} from "../components/CreateCampaign/utils";
import {
  getObjectFromLocalStorage,
  removeCreateCampaignDataFromLocalStorage,
} from "../api/localStorage";
import { loadUserData } from "../services/user";
import { isNeedToShowPostSignUpOnboarding } from "../utils/utils";
import {
  AI_MODEL,
  LANGUAGE_ENGLISH_DEFAULT,
} from "../components/CreateCampaign/constants";

function CreateCampaign(props) {
  const { crm } = props;

  const location = useLocation();
  const { isRecreatingCampaignIframe } = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(!isRecreatingCampaignIframe);
  const [resumeCampaign, setResumeCampaign] = useState(null);
  const [currentTempCampaign, setCurrentTempCampaign] = useState(null);

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const fixBeginDateIfNeeded = (beginDate) => {
    try {
      if (beginDate?.endsWith("T00:00:00.000Z")) {
        const [year, month, day] = beginDate.split(/[^0-9]/);
        const d = new Date();
        d.setFullYear(+year);
        d.setMonth(+month - 1);
        d.setDate(+day);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d.toISOString();
      }
    } catch {}
    return beginDate;
  };

  const fetchTempCampaign = async () => {
    if (!isLoading) {
      return;
    }

    removeCreateCampaignDataFromLocalStorage();

    const res = await getTempCampaign(crm);
    if (res.success && res.result.tempCampaign?.tempJson) {
      const {
        tempCampaign: { createdAt, tempJson, id },
      } = res.result;
      try {
        const parsedTempCampaign = JSON.parse(tempJson);
        const { name, crmType, activeTab } = parsedTempCampaign;
        if (
          name &&
          crmType === crm &&
          Object.values(
            getWizardTabs(crmType, parsedTempCampaign.type) || {}
          ).includes(activeTab)
        ) {
          if (parsedTempCampaign.beginDate) {
            parsedTempCampaign.beginDate = fixBeginDateIfNeeded(
              parsedTempCampaign.beginDate
            );
          }
          if (
            parsedTempCampaign.language &&
            parsedTempCampaign.language !== LANGUAGE_ENGLISH_DEFAULT
          ) {
            const companyInfo = getObjectFromLocalStorage("companyInfo");
            const notHaveNonDefaultAiModel = Object.values(AI_MODEL).some(
              (value) => isNotHaveNonDefaultAiModel(companyInfo, value)
            );
            if (notHaveNonDefaultAiModel) {
              parsedTempCampaign.language = "";
            }
          }
          delete parsedTempCampaign.linksForType;
          setResumeCampaign({
            createdAt,
            tempJson: { ...parsedTempCampaign, tempCampaignId: id },
          });
        }
      } catch {}
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadUserData(null, (res) => {
      setIsLoadingUserData(false);

      if (!isNeedToShowPostSignUpOnboarding(res)) {
        fetchTempCampaign();
      }
    });
  }, []);

  if (isLoading || isLoadingUserData) {
    return (
      <>
        <CheckExtensionVersion />
        <CampaignWizard {...props} isShowLoading />
      </>
    );
  }

  if (resumeCampaign) {
    const handleStartOver = async () => {
      setResumeCampaign(null);
      await resetTempCampaign(crm);
    };
    const handleResume = () => {
      const { tempJson } = resumeCampaign;
      setCurrentTempCampaign(tempJson);
      setResumeCampaign(null);
    };

    const { createdAt } = resumeCampaign;
    const date = new Date(createdAt);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return (
      <>
        <CheckExtensionVersion />
        <CampaignWizard
          {...props}
          isResumeStep={true}
          date={formattedDate}
          handleResume={handleResume}
          handleStartOver={handleStartOver}
        />
      </>
    );
  }

  return (
    <>
      <CheckExtensionVersion />
      <CampaignWizard
        {...props}
        {...{ currentTempCampaign, setCurrentTempCampaign }}
      />
    </>
  );
}

export default CreateCampaign;
