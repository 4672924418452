import React, { useCallback } from "react";

import Loader from "../../../../Loader";
import TableContent from "../Table";
import QuestionModalContent from "../QuestionModalContent";
import ConfirmModalContent from "../ConfirmModalContent";
import { useStateData, useModal } from "../hooks";

import {
  Container,
  Content,
  Title,
  NewQuestionButton,
  LoaderWrapper,
  OverflowContent,
  Filters,
  Filter,
  FilterLabel,
  Select,
} from "./styles";
import CustomSelect from "../../../../CustomSelect";

function MegaPageWrapper() {
  const {
    state,
    setState,
    handleSaveMegaPageData,
    handleRegenerateMegaPageData,
    handleRemoveMegaPageData,
  } = useStateData();

  const questionModal = useModal(false);
  const confirmModal = useModal(false);

  const handleOpenQuestionModal = useCallback(
    (event, data) => {
      event?.preventDefault();

      setState((prevState) => ({
        ...prevState,
        selectedData: data?.id
          ? state.megaPageData.find((item) => item.id === data.id) || {}
          : {},
      }));

      questionModal.open();
    },
    [state.megaPageData, setState, questionModal]
  );

  const handleOpenConfirmModal = useCallback(
    (event, data) => {
      event?.preventDefault();
      setState((prevState) => ({ ...prevState, selectedData: data }));
      confirmModal.open();
    },
    [setState, confirmModal]
  );

  const handleChangeFilter = (filterName, value) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [filterName]: value,
      },
    }));
  };

  return (
    <Container>
      <Content>
        <Title>Mega Page</Title>

        <NewQuestionButton
          onClick={handleOpenQuestionModal}
          disabled={state.isLoading}
        >
          New Question
        </NewQuestionButton>

        <Filters>
          <Filter>
            <FilterLabel>Page</FilterLabel>
            <Select $noSelected={!state.filters.megaPageId}>
              <CustomSelect
                options={state.megaPages}
                title="Select Page..."
                width="100%"
                selected={state.filters.megaPageId}
                onSelect={(value) => handleChangeFilter("megaPageId", value)}
              />
            </Select>
          </Filter>
        </Filters>

        <QuestionModalContent
          state={state}
          questionModal={questionModal}
          setState={setState}
          handleSaveMegaPageData={handleSaveMegaPageData}
        />

        <ConfirmModalContent
          state={state}
          confirmModal={confirmModal}
          setState={setState}
          handleRemoveMegaPageData={handleRemoveMegaPageData}
        />

        {state.isLoading && (
          <LoaderWrapper>
            <Loader parentSize={true} padding="0" />
          </LoaderWrapper>
        )}

        <OverflowContent>
          <TableContent
            isLoading={state.isLoading}
            megaPageData={state.megaPageData}
            onEdit={handleOpenQuestionModal}
            onRegenerate={handleRegenerateMegaPageData}
            onRemove={handleOpenConfirmModal}
          />
        </OverflowContent>
      </Content>
    </Container>
  );
}

export default MegaPageWrapper;
