import React from "react";

import { MsalProvider } from "@azure/msal-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "../utils/auth-o365-config";
import { gsuiteOauthClientId } from "../utils/config";

const msalInstance = new PublicClientApplication(msalConfig);

const AuthProviders = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId={gsuiteOauthClientId}>
        {children}
      </GoogleOAuthProvider>
    </MsalProvider>
  );
};

export default AuthProviders;
