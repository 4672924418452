import React from "react";

import Button from "./Button";
import { publicUrl } from "../../utils/config";

function O365Button({ onClick, isLastUsed, isLogin }) {
  return (
    <Button
      title={`Sign ${isLogin ? "in" : "up"} with Microsoft`}
      onClick={onClick}
      styles={{
        backgroundColor: "#2D3748",
        backgroundColorActive: "#252525",
        backgroundColorHover: "#292929",
        logoUrl: `${publicUrl}/images/microsoft-icon.svg`,
      }}
      isLastUsed={isLastUsed}
    />
  );
}

export default O365Button;
