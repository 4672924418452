import React, { useEffect, useState } from "react";
import styled from "styled-components";

import StepButton from "../../StepButton";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import ConfirmPopup from "../../ConfirmPopup";
import {
  ButtonGroup,
  InputTextarea,
  InputTextareaBlock,
  OFFSET_LEFT,
  OFFSET_LEFT_BTN_GR,
  Section,
} from "../styles";
import SaveDraftButton from "./SaveDraftButton";

function JobDescription({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
  currentTempCampaign,
}) {
  const jobDescriptionFromStorage = {
    ...campaign.localBullhornData?.jobDescription,
  };

  const [jobDescription, setJobDescription] = useState("");
  const [publishedDescription, setPublishedDescription] = useState("");
  const [jobDescriptionKey, setJobDescriptionKey] = useState("");
  const [isCheckboxActive, setIsCheckboxActive] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const [isJobDescriptionCleared, setIsJobDescriptionCleared] = useState(true);
  const [isPublishedDescriptionCleared, setIsPublishedDescriptionCleared] =
    useState(true);

  const [isJobDescriptionChanged, setIsJobDescriptionChanged] = useState(false);
  const [isPublishedDescriptionChanged, setIsPublishedDescriptionChanged] =
    useState(false);

  const applyCampaignChanges = (asDraft) => {
    const jobDescriptionChanged = isChanged();

    setCampaign((campaign) => ({
      ...campaign,
      jobDescriptionKey,
      jobDescription: {
        jobDescription,
        publishedDescription,
      },
      jobDescriptionChanged,
      asDraft,
    }));
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    applyCampaignChanges();
    handleDecreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();
    applyCampaignChanges(true);
  };

  const handleClickNext = async (event) => {
    event.preventDefault();

    if (
      (jobDescriptionKey === "jobDescription" && jobDescription === "") ||
      (jobDescriptionKey === "publishedDescription" &&
        publishedDescription === "")
    ) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Please enter job description",
        },
      });
      handleIncreaseStep();
      return;
    }

    const jobDescriptionChanged = isChanged();

    setCampaign((campaign) => ({
      ...campaign,
      jobDescriptionKey,
      jobDescription: {
        jobDescription,
        publishedDescription,
      },
      jobDescriptionChanged,
    }));

    handleIncreaseStep();
  };

  const isChanged = () => {
    return (
      currentTempCampaign.jobDescriptionKey !== jobDescriptionKey ||
      (jobDescriptionKey === "jobDescription" && isJobDescriptionChanged) ||
      (jobDescriptionKey === "publishedDescription" &&
        isPublishedDescriptionChanged)
    );
  };

  const toggleCheckbox = (isChecked) => {
    if (
      (jobDescriptionKey === "jobDescription" && isJobDescriptionChanged) ||
      (jobDescriptionKey === "publishedDescription" &&
        isPublishedDescriptionChanged)
    ) {
      setIsConfirmPopupOpen(true);
      return;
    }

    changeDescriptionKey(isChecked);
  };

  const changeDescriptionKey = (isChecked, confirmed = false) => {
    setIsCheckboxChecked(isChecked);
    setJobDescriptionKey(isChecked ? "publishedDescription" : "jobDescription");

    if (isChecked && confirmed) {
      setJobDescription(jobDescriptionFromStorage.jobDescription);
      setIsJobDescriptionCleared(true);
    }

    if (!isChecked && confirmed) {
      setPublishedDescription(jobDescriptionFromStorage.publishedDescription);
      setIsPublishedDescriptionCleared(true);
    }

    setIsJobDescriptionChanged(false);
    setIsPublishedDescriptionChanged(false);
  };

  const confirmToggle = () => {
    changeDescriptionKey(!isCheckboxChecked, true);
    cancelToggle();
  };

  const cancelToggle = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleChangeJobDescription = (value) => {
    if (isJobDescriptionCleared) {
      setIsJobDescriptionChanged(false);
      setIsJobDescriptionCleared(false);
      setJobDescription(value);
      return;
    }
    setIsJobDescriptionChanged(true);
    setJobDescription(value);
  };

  const handleChangePublishedDescription = (value) => {
    if (isPublishedDescriptionCleared) {
      setIsPublishedDescriptionChanged(false);
      setIsPublishedDescriptionCleared(false);
      setPublishedDescription(value);
      return;
    }
    setIsPublishedDescriptionChanged(true);
    setPublishedDescription(value);
  };

  useEffect(() => {
    if (campaign.jobDescriptionKey !== "") {
      setJobDescriptionKey(campaign.jobDescriptionKey);
      setJobDescription(campaign.jobDescription.jobDescription);
      setPublishedDescription(campaign.jobDescription.publishedDescription);

      const isJobDescriptionFull =
        campaign.jobDescription.jobDescription !== "" &&
        campaign.jobDescription.publishedDescription !== "";

      setIsCheckboxActive(isJobDescriptionFull);
      if (campaign.jobDescriptionKey === "jobDescription") {
        setIsCheckboxChecked(false);
      }

      return;
    }

    const isJobDescriptionFull =
      jobDescriptionFromStorage.jobDescription !== "" &&
      jobDescriptionFromStorage.publishedDescription !== "";

    setIsCheckboxActive(isJobDescriptionFull);

    const key =
      jobDescriptionFromStorage &&
      jobDescriptionFromStorage.publishedDescription !== ""
        ? "publishedDescription"
        : "jobDescription";

    if (key === "jobDescription") {
      setIsCheckboxChecked(false);
    }

    setJobDescriptionKey(key);
    setJobDescription(jobDescriptionFromStorage.jobDescription);
    setPublishedDescription(jobDescriptionFromStorage.publishedDescription);

    setCampaign((campaign) => ({
      ...campaign,
      jobDescriptionKey: key,
      jobDescription: jobDescriptionFromStorage,
      jobDescriptionChanged: false,
    }));
  }, []);

  const quillSettings = {
    theme: "snow",
    modules: {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          [{ align: [] }],
          [{ color: [] }],
          [
            "bold",
            "italic",
            "underline",
            "strike",
            "clean",
            { list: "bullet" },
            { list: "ordered" },
            "link",
            "code-block",
            "blockquote",
          ],
        ],
      },
      "table-better": {},
    },
  };

  return (
    <>
      <Section>
        {isConfirmPopupOpen && (
          <ConfirmPopup
            title="Overwrite Changes?"
            description={`You’re about to overwrite your changes with the ${
              jobDescriptionKey === "jobDescription" ? "Job" : "Published"
            } Description. Are you sure?`}
            onConfirm={confirmToggle}
            onCancel={cancelToggle}
            confirmLabel="Overwrite"
          />
        )}

        <Title>Job Description</Title>
        <InputBlock>
          <Label fontWeight="bold" htmlFor="job-description">
            Enter or modify the job description you plan to promote in the
            campaign
          </Label>

          <CheckboxBlock>
            <input
              id="jobDescriptionCheckbox"
              type="checkbox"
              checked={isCheckboxChecked}
              value={isCheckboxChecked}
              disabled={!isCheckboxActive}
              onChange={(event) => toggleCheckbox(event.target.checked)}
            />
            <NativeLabel
              htmlFor="jobDescriptionCheckbox"
              className="toggle"
              $disabled={!isCheckboxActive}
            />
            <CheckboxLabel>
              {jobDescriptionKey === "jobDescription" ? (
                <div>
                  Use <span>Job Description</span>
                </div>
              ) : (
                <div>
                  Use <span>Published Description</span>
                </div>
              )}
            </CheckboxLabel>
          </CheckboxBlock>

          <InputTextareaBlock>
            <InputTextarea
              $hide={jobDescriptionKey !== "jobDescription"}
              id="job-description"
              placeholder="Job Description"
              value={jobDescription}
              onChange={handleChangeJobDescription}
              {...quillSettings}
            />

            <InputTextarea
              $hide={jobDescriptionKey !== "publishedDescription"}
              id="job-description"
              placeholder="Job Description"
              value={publishedDescription}
              onChange={handleChangePublishedDescription}
              {...quillSettings}
            />
          </InputTextareaBlock>
        </InputBlock>
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton
          onClick={handleClickNext}
          disabled={
            (jobDescriptionKey === "jobDescription" && jobDescription === "") ||
            (jobDescriptionKey === "publishedDescription" &&
              publishedDescription === "")
          }
          isNext={true}
        />
        <SaveDraftButton onClick={handleClickSaveDraft} />
      </ButtonGroup>
    </>
  );
}

export default JobDescription;

const Title = styled.div`
  padding: 19px ${OFFSET_LEFT} 0;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;

const InputBlock = styled.div`
  padding: 0 ${OFFSET_LEFT_BTN_GR};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Label = styled.label`
  margin: 0 calc(${OFFSET_LEFT} - ${OFFSET_LEFT_BTN_GR});
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin: 10px 0 5px calc(${OFFSET_LEFT} - ${OFFSET_LEFT_BTN_GR});
`;

const NativeLabel = styled.label`
  background-color: transparent !important;
  border: 1px solid ${({ $disabled }) => ($disabled ? "#c9c9c9" : "#66952a")} !important;
  &::after {
    background-color: ${({ $disabled }) =>
      $disabled ? "#c9c9c9" : "#66952a"} !important;
  }
  cursor: ${({ $disabled }) =>
    $disabled ? "not-allowed" : "pointer"} !important;
`;

const CheckboxLabel = styled.div`
  * {
    font-family: "Poppins", sans-serif;
  }
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  margin-left: 10px;
  span {
    font-weight: 600;
  }
`;
