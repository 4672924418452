import { fetchTransactionalEmails } from "./queries";

const getTransactionalEmails = async (filters) => {
  try {
    const result = await fetchTransactionalEmails(filters);

    if (result.message) {
      return result;
    }

    return { success: true, emails: result };
  } catch (error) {
    return { success: false, message: "Get Transactional Emails failed" };
  }
};

export { getTransactionalEmails };
