import styled, { css } from "styled-components";

export const UploaderContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  width: 488px;
`;

export const UploadedImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 3px;
  border: 1px solid #d6ddeb;
  margin-right: 10px;
  ${({ $noImage }) =>
    $noImage
      ? css`
          background-color: #f6f6f6;
          background-image: url(/images/avatar-uploader-bg-icon.png);
          background-position: center bottom;
          background-repeat: no-repeat;
        `
      : css`
          border-style: dotted;
        `}
  img {
    max-width: 70px;
    max-height: 70px;
  }
`;

export const DragDropArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 82px);
  height: 72px;
  border-radius: 3px;
  border: 1px dashed #d6ddeb;
  background-color: #f6f6f6;
`;

export const DragDropAreaText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #757678;
`;

export const UploadBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #646464;
  border: 1px solid #646464;
  border-radius: 6px;
  width: 76px;
  height: 30px;
  font-family: "AlbertSansExtraBold";
  background-color: #f6f6f6;
  margin-left: 10px;
  cursor: pointer;
`;
