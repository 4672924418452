import React, { useEffect, useState } from "react";

import TableContent from "../Table";
import BlogPostFormPopup from "../BlogPostFormPopup";
import ConfirmPopup from "../../../../ConfirmPopup";
import Loader from "../../../../Loader";
import {
  createBlogPost,
  getBlogPosts,
  updateBlogPost,
} from "../../../../../services/blogPosts";
import { sendMessageToExtension } from "../../../../../utils/postToExtension";

import {
  Container,
  Content,
  CreatePostButton,
  LoaderWrapper,
  Title,
  OverflowContent,
} from "./styles";

function BlogPostsWrapper() {
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [blogPostUrl, setBlogPostUrl] = useState(null);
  const [blogPosts, setPosts] = useState([]);

  const handleError = (message, defaultMessage) => {
    setIsLoading(false);
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: message?.message || message || defaultMessage,
      },
    });
  };

  const fetchBlogPosts = async () => {
    setIsLoading(true);
    const result = await getBlogPosts();

    if (!result?.success) {
      handleError(result.message, "Failed to fetch blog posts");
      return;
    }

    setPosts(result.posts);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const handleOpenPopup = (event, id) => {
    event?.preventDefault();

    if (id) {
      const currentBlogPost = blogPosts.find((blogPost) => blogPost.id === id);

      if (!currentBlogPost) return;
      setPopupData(currentBlogPost);
      return;
    }

    setPopupData({});
  };

  const handleCreateBlogPost = async (data) => {
    setIsLoading(true);
    const result = await createBlogPost(data);

    if (!result?.success) {
      handleError(result.message, "Blog post was not created");
      return;
    }

    if (data.isFeatured) {
      // If the new blog post is featured, ensure all other posts have isFeatured set to false
      // This ensures only one blog post can be featured at a time (matching server-side behavior)
      setPosts((prev) => [
        ...prev.map((post) => ({ ...post, isFeatured: false })),
        result.result.post,
      ]);
    } else {
      setPosts((prev) => [...prev, result.result.post]);
    }

    setIsLoading(false);
  };

  const handleUpdateBlogPost = async (data) => {
    if (!data?.id) {
      handleError({}, "Invalid blog post data");
      return;
    }

    setIsLoading(true);
    const result = await updateBlogPost(data.id, data);

    if (!result?.success) {
      handleError(result, "Blog post was not updated");
      return;
    }

    setPosts((prevPosts) => {
      if (data.isFeatured) {
        // If the updated blog post is marked as featured, ensure all other posts have isFeatured set to false
        // This ensures only one blog post can be featured at a time (matching server-side behavior)
        return prevPosts.map((blogPost) => {
          if (blogPost.id === data.id) {
            return { ...blogPost, ...(result?.result?.post ?? data) };
          }
          return { ...blogPost, isFeatured: false };
        });
      } else {
        return prevPosts.map((blogPost) =>
          blogPost.id === data.id
            ? { ...blogPost, ...(result?.result?.post ?? data) }
            : blogPost
        );
      }
    });

    setIsLoading(false);
  };

  const handleSavePost = (data) => {
    setPopupData(null);

    if (!data.id) {
      handleCreateBlogPost(data);
      return;
    }

    handleUpdateBlogPost(data);
  };

  const handleRemovePost = async (id) => {
    setIsLoading(true);
    const result = await updateBlogPost(id, { isRemoved: true });

    if (!result?.success) {
      handleError(result.message, "Blog post didn't remove");
      return;
    }

    setIsLoading(false);
    setPosts(blogPosts.filter((blogPost) => blogPost.id !== id));
  };

  const handleToggleActive = async (id, isActive) => {
    setIsLoading(true);

    const result = await updateBlogPost(id, { isActive });

    if (!result?.success) {
      const message = isActive
        ? "Failed to activate the blog post"
        : "Failed to deactivate the blog post";

      handleError(result.message, message);

      return;
    }

    setIsLoading(false);

    const updatedPosts = blogPosts.map((blogPost) =>
      blogPost.id === id ? { ...blogPost, isActive } : blogPost
    );

    setPosts(updatedPosts);
  };

  const handleOpenLinkPopup = (url) => {
    setBlogPostUrl(url);
  };

  const openBlogPostPage = () => {
    try {
      window.open(blogPostUrl, "_blank");
    } catch (error) {
      handleError(error.message, "Failed to open blog post link");
    }
  };

  return (
    <Container>
      <Content>
        <Title>Blog Posts</Title>

        <CreatePostButton onClick={handleOpenPopup} disabled={isLoading}>
          Create Post
        </CreatePostButton>

        {Boolean(popupData) && (
          <BlogPostFormPopup
            allBlogPosts={blogPosts}
            blogPost={popupData}
            onClose={() => setPopupData(null)}
            onSave={handleSavePost}
          />
        )}

        {Boolean(blogPostUrl) && (
          <ConfirmPopup
            title="Blog Post Link"
            description={blogPostUrl}
            onConfirm={openBlogPostPage}
            onCancel={() => setBlogPostUrl(null)}
            confirmLabel="Open Link"
            cancelLabel="Maybe later"
          />
        )}

        {isLoading && (
          <LoaderWrapper>
            <Loader parentSize={true} padding="0" />
          </LoaderWrapper>
        )}

        <OverflowContent>
          <TableContent
            blogPosts={blogPosts}
            onEdit={handleOpenPopup}
            onRemove={handleRemovePost}
            onToggleActive={handleToggleActive}
            onOpenLinkPopup={handleOpenLinkPopup}
          />
        </OverflowContent>
      </Content>
    </Container>
  );
}

export default BlogPostsWrapper;
