import { useState } from "react";

import { fileToBase64 } from "../../../../../utils/utils";

export const useFormState = (initialData = {}, allPosts) => {
  const getInitialState = () => ({
    formData: {
      title: initialData.title || "",
      subTitle: initialData.subTitle || "",
      writer: initialData.writer || "",
      profilePicture: initialData.profilePicture || "",
      tag: initialData.tag || "",
      publishDate: initialData.publishDate || "",
      relatedArticles: initialData.relatedArticles?.length
        ? initialData.relatedArticles
        : [],
      heroImage: initialData.heroImage || "",
      isFeatured: initialData.isFeatured || false,
      text: initialData.text || "",
      link: initialData.link || "",
    },
    relatedArticleOptions: getRelatedArticleOptions(),
    errorMessages: {},
  });

  const [formState, setFormState] = useState(getInitialState());

  function getRelatedArticleOptions() {
    return (allPosts || [])
      .filter((post) => post.id !== initialData?.id)
      .map((post) => ({
        value: post.id,
        label: post.title,
      }));
  }

  const updateFormData = (updates) => {
    setFormState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, ...updates },
    }));
  };

  const handleChange = (name, value) => {
    updateFormData({ [name]: value });
  };

  const handleEventChange = (event) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  const handleTextChange = (value, _delta, _source, editor) => {
    const text = editor.getText().trim();
    const html = editor.getHTML().trim();

    // We check that the HTML consists only of empty <p>...</p>, spaces or <br>
    handleChange(
      "text",
      text && !/^(\s*<p>\s*(<br\s*\/?>|\s*)\s*<\/p>\s*)+$/.test(html)
        ? value
        : ""
    );
  };

  const handleDateChange = (date) => {
    handleChange("publishDate", date?.toISOString() || "");
  };

  const handleLoadFile = (name, file) => {
    fileToBase64(file, (base64String) => {
      handleChange(name, base64String);
    });
  };

  const handleArticlesChange = (articles) => {
    handleChange(
      "relatedArticles",
      articles.map((article) => article.value)
    );
  };

  const handleLoadProfilePicture = (file) => {
    handleLoadFile("profilePicture", file);
  };

  const handleLoadHeroImage = (file) => {
    handleLoadFile("heroImage", file);
  };

  const handleFeaturedChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name, checked);
  };

  const setErrorMessages = (errors) => {
    setFormState((prevState) => ({
      ...prevState,
      errorMessages: { ...prevState.errorMessages, ...errors },
    }));
  };

  const validateForm = () => {
    const errors = {};
    const {
      title,
      subTitle,
      writer,
      profilePicture,
      tag,
      publishDate,
      heroImage,
      text,
      link,
    } = formState.formData;

    if (!title) errors.title = "Missed title";
    if (!subTitle) errors.subTitle = "Missed sub-title";
    if (!writer) errors.writer = "Missed writer";
    if (!profilePicture) errors.profilePicture = "Missed profile picture";
    if (!tag) errors.tag = "Missed tag";
    if (!publishDate) errors.publishDate = "Missed publish date";
    if (!heroImage) errors.heroImage = "Missed hero image";
    if (!text) errors.text = "Missed text";
    if (!link) errors.link = "Missed link";

    return errors;
  };

  const getFormData = () => {
    return {
      ...(initialData?.id ? { ...initialData } : {}),
      ...formState.formData,
    };
  };

  return {
    formState,
    handleEventChange,
    handleTextChange,
    handleDateChange,
    handleArticlesChange,
    handleLoadProfilePicture,
    handleLoadHeroImage,
    handleFeaturedChange,
    setErrorMessages,
    validateForm,
    getFormData,
  };
};
