import { useCallback, useEffect, useState } from "react";

import { sendMessageToExtension } from "../../../../../utils/postToExtension";
import { getTransactionalEmails } from "../../../../../services/transactionalEmails";

export function useStateData() {
  const [state, setState] = useState({
    isLoading: false,
    emails: [],
    filters: {
      types: [],
      dateRange: {},
    },
  });

  const setLoading = (isLoading) => {
    setState((prevState) => ({ ...prevState, isLoading }));
  };

  const handleError = useCallback((message, defaultMessage) => {
    setLoading(false);
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: message || defaultMessage,
      },
    });
  }, []);

  const fetchTransactionalEmails = useCallback(async () => {
    setLoading(true);

    const result = await getTransactionalEmails(state.filters);

    if (!result?.success) {
      handleError(result.message, "Failed to fetch Transactional Emails");
      return;
    }

    setState((prevState) => ({
      ...prevState,
      emails: result.emails,
    }));
    setLoading(false);
  }, [handleError, state.filters]);

  useEffect(() => {
    fetchTransactionalEmails();
  }, [fetchTransactionalEmails, state.filters]);

  return {
    state,
    setState,
  };
}
