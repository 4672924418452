import styled from "styled-components";

export const Button = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  width: 46px;
  height: 31.5px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;

  img {
    height: 18px;
    width: 18px;
    opacity: 0.75;
    transition: all 0.3s;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
`;
