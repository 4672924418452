import React, { useEffect } from "react";

import SettingsProvider from "../SettingsProvider";
import { loadUserData } from "../../../services/user";
import { useBaseSettings } from "../useBaseSettings";
import { useSidebarTabs } from "./hooks/useSidebarTabs";
import { useAuth } from "./hooks/useAuth";

import Layout from "../Layout";
import Sidebar from "../Sidebar";
import Content from "../Content";
import LogoutButton from "./LogoutButton";
import EnvDisplay from "./EnvDisplay";

const Admin = ({ loginPath }) => {
  const { isLoading, setIsLoadingUserData, fetchIntegrations } =
    useBaseSettings();
  const { handleLogout, userInfo } = useAuth(loginPath);
  const { activeTabsPath, setActiveTabsPath, sidebarTabs, handleTabClick } =
    useSidebarTabs(fetchIntegrations);

  useEffect(() => {
    loadUserData(userInfo, () => {
      setIsLoadingUserData(false);

      fetchIntegrations(activeTabsPath);
    });
  }, []);

  return (
    <SettingsProvider
      sidebarTabs={sidebarTabs}
      handleTabClick={handleTabClick}
      activeTabsPath={activeTabsPath}
      setActiveTabsPath={setActiveTabsPath}
    >
      <LogoutButton handleClick={handleLogout} />
      <Layout>
        <Sidebar>
          <EnvDisplay />
        </Sidebar>
        <Content isLoading={isLoading} />
      </Layout>
    </SettingsProvider>
  );
};

export default Admin;
