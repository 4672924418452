import { useCallback, useMemo, useState } from "react";
import { getSidebarConfig } from "../sidebarConfig";

export const useSidebarTabs = (fetchIntegrations) => {
  const [filter, setFilter] = useState({});
  const [activeTabsPath, setActiveTabsPath] = useState([
    "admin",
    "simpleConnect",
  ]);

  const openCampaignsWithFilter = useCallback((newFilter) => {
    setFilter(newFilter);
    setActiveTabsPath(["admin", "campaigns"]);
  }, []);

  const handleTabClick = useCallback((_, path) => {
    setActiveTabsPath(path);
  }, []);

  const sidebarTabs = useMemo(
    () =>
      getSidebarConfig({
        activeTabsPath,
        fetchIntegrations,
        openCampaignsWithFilter,
        filter,
      }),
    [activeTabsPath, fetchIntegrations, filter, openCampaignsWithFilter]
  );

  return {
    filter,
    activeTabsPath,
    setActiveTabsPath,
    sidebarTabs,
    handleTabClick,
  };
};
