import React from "react";
import { SettingsContext } from "./context";

const SettingsProvider = ({
  children,
  sidebarTabs,
  handleTabClick,
  activeTabsPath,
  setActiveTabsPath,
}) => {
  return (
    <SettingsContext.Provider
      value={{
        sidebarTabs,
        handleTabClick,
        activeTabsPath,
        setActiveTabsPath,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
