import React from "react";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";

import TableContent from "../Table";
import Loader from "../../../../../Loader";
import { useStateData } from "../hooks";
import { TRANSACTIONAL_EMAIL_OPTIONS } from "../../../../../../utils/constants";
import { exportToCSV } from "../../../../../../utils/export";

import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Content,
  Filter,
  FilterLabel,
  Filters,
  Title,
  LoaderWrapper,
  OverflowContent,
  ExportButton,
} from "./styles";
import { DateRange } from "../../../../../styles";

const TABLE_COLUMN_NAMES = [
  { name: "type", label: "Type" },
  { name: "sentOn", label: "Sent on" },
  { name: "readOn", label: "Read on" },
];

function TransactionalEmailsWrapper() {
  const { state, setState } = useStateData();
  const { isLoading, emails, filters } = state;

  const handleChangeSelect = (filterName, selectOption) => {
    setState((prevState) => {
      const newFilters = {
        ...prevState.filters,
        [filterName]: selectOption.map((option) => option.value),
      };

      return {
        ...prevState,
        filters: newFilters,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setState((prevState) => {
      const dateRange = { ...prevState.filters.dateRange };

      if (value) {
        if (name === "endDate") {
          value.setHours(23, 59, 59, 999);
        }
        dateRange[name] = value;
      } else {
        delete dateRange[name];
      }

      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          dateRange,
        },
      };
    });
  };

  const exportToFile = () => {
    exportToCSV(TABLE_COLUMN_NAMES, emails, "transactional-emails.csv");
  };

  return (
    <Container>
      <Content>
        <Title>Transactional Emails</Title>

        {isLoading && (
          <LoaderWrapper>
            <Loader parentSize={true} padding="0" />
          </LoaderWrapper>
        )}

        <Filters>
          <Filter>
            <FilterLabel>Type</FilterLabel>
            <MultiSelect
              options={TRANSACTIONAL_EMAIL_OPTIONS}
              value={TRANSACTIONAL_EMAIL_OPTIONS.filter((email) =>
                filters.types.includes(email.value)
              )}
              onChange={(selectOption) =>
                handleChangeSelect("types", selectOption)
              }
              labelledBy="Company"
              hasSelectAll={false}
              disableSearch={true}
              closeOnChangedValue={true}
              display="chip"
            />
          </Filter>
          <Filter>
            <FilterLabel>Date Range</FilterLabel>
            <DateRange>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={filters.dateRange.startDate}
                onChange={(date) => handleChangeDate("startDate", date)}
                selectsStart
                startDate={filters.dateRange.startDate}
                endDate={filters.dateRange.endDate}
              />
              <span>-</span>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={filters.dateRange.endDate}
                onChange={(date) => handleChangeDate("endDate", date)}
                selectsEnd
                startDate={filters.dateRange.startDate}
                endDate={filters.dateRange.endDate}
                minDate={filters.dateRange.startDate}
              />
            </DateRange>
          </Filter>
          <ExportButton disabled={isLoading} onClick={exportToFile}>
            Export
          </ExportButton>
        </Filters>

        <OverflowContent>
          <TableContent
            isLoading={isLoading}
            tableColumnNames={TABLE_COLUMN_NAMES}
            emails={emails}
          />
        </OverflowContent>
      </Content>
    </Container>
  );
}

export default TransactionalEmailsWrapper;
