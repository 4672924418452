import { useState } from "react";
import { getIntegrations } from "../../services/integrations";
import { sendMessageToExtension } from "../../utils/postToExtension";

export const useBaseSettings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [integrations, setIntegrations] = useState({});
  const [connectedServices, setConnectedServices] = useState([]);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const fetchIntegrations = async (activeTabsPath) => {
    const integrations = await getIntegrations();

    if (!integrations.integration) {
      if (
        integrations.message &&
        activeTabsPath.includes("integrations") &&
        activeTabsPath.includes("emailService")
      ) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: integrations.message,
          },
        });
      }
      setIsLoading(false);
      return;
    }

    if (
      integrations.integration.crm &&
      Object.keys(integrations.integration.crm).length > 0
    ) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "crm",
      ]);
    }

    if (integrations.integration.email?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "emailService",
      ]);
    }

    if (integrations.integration.sms?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "smsService",
      ]);
    }

    if (integrations.integration.aiModel?.type) {
      setConnectedServices((oldConnectedServices) => [
        ...oldConnectedServices,
        "aiModel",
      ]);
    }

    setIntegrations(integrations.integration);
    setIsLoading(false);

    return integrations.integration;
  };

  return {
    isLoading,
    integrations,
    setIntegrations,
    connectedServices,
    setConnectedServices,
    isLoadingUserData,
    setIsLoadingUserData,
    fetchIntegrations,
  };
};
