/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import { CRMS } from "../../../../utils/constants";
import {
  getCrmCampaignTypes,
  isBullhornMarketingCampaignType,
} from "../../utils";
import {
  ErrorMessage,
  OFFSET_LEFT,
  RadioBlock,
  RadioButtonGroup,
  RadioInput,
} from "../../styles";
import DefineNewType from "../DefineNewType";
import {
  GOALS,
  NATIONBUILDER_CAMPAIGN_GOALS_FOR_TYPES,
  NATIONBUILDER_CAMPAIGN_TYPES,
} from "../../constants";
import AdvancedSelect from "../../../AdvancedSelect";

const LABELS = {};

function CampaignType({
  setCampaignType = () => {},
  campaign,
  isCustomTypeInputOpened,
  setIsCustomTypeInputOpened,
  selectedType,
  setType,
  customType,
  setCustomType,
  customTypeErrorMessage,
  setCustomTypeErrorMessage,
  errorMessage,
  setErrorMessage,
  linksForType = {},
  link = "",
  setLink = () => {},
  selectedGoal = "",
  setGoal = () => {},
  setCustomGoal = () => {},
}) {
  const isBullhornCrm = campaign.crmType === CRMS.BULLHORN;
  const isNationBuilderCrm = campaign.crmType === CRMS.NATIONBUILDER;
  const campaignTypeList = getCrmCampaignTypes(campaign.crmType);
  const isRequiredLink = isNationBuilderCrm && selectedGoal === GOALS.visitLink;

  const getLinkOptions = (type, includeCurrent = true) => {
    if (
      type !== NATIONBUILDER_CAMPAIGN_TYPES.askForDonations &&
      type !== NATIONBUILDER_CAMPAIGN_TYPES.promoteAnEvent
    ) {
      return [];
    }

    const options = (
      linksForType[
        type === NATIONBUILDER_CAMPAIGN_TYPES.askForDonations
          ? "donations"
          : "events"
      ] || []
    ).map((p) => ({ value: p.url, label: p.name }));

    if (includeCurrent && link && !options.some((o) => o.value === link)) {
      options.push({
        value: link,
        label: link.split("/").pop(),
      });
    }

    return options;
  };

  const handleChange = (selType) => {
    if (selType === selectedType) {
      return;
    }

    setErrorMessage("");
    setCustomTypeErrorMessage("");

    setType(selType);
    setIsCustomTypeInputOpened(false);
    setCustomType("");

    if (isBullhornCrm) {
      setCampaignType(selType);
    }

    if (isNationBuilderCrm) {
      const linkOptions = getLinkOptions(selType, false);
      setCustomGoal("");
      setGoal(NATIONBUILDER_CAMPAIGN_GOALS_FOR_TYPES[selType]);
      setLink(linkOptions.length === 1 ? linkOptions[0].value : "");
    }
  };

  const handleClickDefineNewType = () => {
    setIsCustomTypeInputOpened(true);

    setErrorMessage("");
    setCustomTypeErrorMessage("");

    setType("");

    if (isNationBuilderCrm) {
      setCustomGoal("");
      setGoal("");
      setLink("");
    }
  };

  const handleCancelDefineNewType = () => {
    setIsCustomTypeInputOpened(false);
    setCustomTypeErrorMessage("");
  };

  const handleCreateNewType = (newType) => {
    setIsCustomTypeInputOpened(false);
    setCustomTypeErrorMessage("");

    const newTypeLc = newType.toLowerCase();
    const existingType = campaignTypeList.find(
      (g) => g.toLowerCase() === newTypeLc
    );
    if (existingType) {
      handleChange(existingType);
      return;
    }

    setCustomType(newType);

    if (isNationBuilderCrm) {
      setCustomGoal(newType);
      setGoal("");
      setLink("");
    }
  };

  useEffect(() => {
    setIsCustomTypeInputOpened(false);

    if (!campaignTypeList.includes(campaign.type) && campaign.type !== "") {
      setCustomType(campaign.type);
    }
  }, [campaign.type]);

  return (
    <>
      <SelectBlock>
        <Label>What is the purpose of your campaign?</Label>
      </SelectBlock>
      <RadioButtonGroup>
        {campaignTypeList.map((type, index) => {
          const isDisabled =
            !!campaign.localBullhornData?.isMarketingCampaign &&
            !isBullhornMarketingCampaignType(type);
          const isChecked = type === selectedType;
          return (
            <RadioBlock key={index}>
              <RadioInput
                $disabled={isDisabled}
                $checked={isChecked}
                onClick={isDisabled ? undefined : () => handleChange(type)}
                data-tooltip-id={isDisabled ? `no-job-tooltip` : null}
                data-tooltip-content={
                  isDisabled
                    ? "You must navigate to a Job before starting a Recruitment campaign"
                    : null
                }
              >
                <div />
                <span>{LABELS[type] || type}</span>
              </RadioInput>
              {isDisabled ? (
                <Tooltip
                  id={`no-job-tooltip`}
                  className="custom-tooltip"
                  place="right"
                />
              ) : (
                isRequiredLink &&
                isChecked && (
                  <>
                    <AdvancedSelect
                      margin="0 0 0 21px"
                      disableSearch
                      placeholder="Select page..."
                      overrideStrings={{ noOptions: "No pages" }}
                      label="Links"
                      options={getLinkOptions(type)}
                      value={link || null}
                      onSelect={(v) => {
                        setLink(v || "");
                        setErrorMessage("");
                        setCustomTypeErrorMessage("");
                      }}
                      dataTooltip={{
                        id: link ? "goal-link" : null,
                        content: link || null,
                      }}
                    />
                    {!!link && (
                      <Tooltip
                        id="goal-link"
                        className="custom-tooltip"
                        place="right"
                      />
                    )}
                  </>
                )
              )}
            </RadioBlock>
          );
        })}
        {!!customType && (
          <RadioBlock key={customType}>
            <RadioInput $checked={true}>
              <div />
              <span>{customType}</span>
            </RadioInput>
          </RadioBlock>
        )}
      </RadioButtonGroup>

      {!isBullhornCrm ? (
        <DefineNewType
          label="Campaign Type"
          isOpened={isCustomTypeInputOpened}
          onOpen={handleClickDefineNewType}
          onClose={handleCancelDefineNewType}
          onCreate={handleCreateNewType}
          onInput={() => setCustomTypeErrorMessage("")}
          errorMessage={customTypeErrorMessage}
        />
      ) : null}

      {errorMessage !== "" && (
        <ErrorMessage $marginLeft={OFFSET_LEFT}>{errorMessage}</ErrorMessage>
      )}
    </>
  );
}

export default CampaignType;

const SelectBlock = styled.div`
  padding: 39px ${OFFSET_LEFT} 17px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
`;

const Label = styled.label`
  margin: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;
