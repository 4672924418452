import React, { useState } from "react";
import { styled } from "styled-components";

const Search = ({ value, onChange }) => {
  const [searchString, setSearchString] = useState(value || "");

  const handleChange = (newValue) => {
    setSearchString(newValue);
    onChange(newValue);
  };

  const handleClear = () => {
    handleChange("");
  };

  return (
    <SearchInputCont>
      <SearchInput
        type="text"
        onChange={(e) => handleChange(e.target.value)}
        value={searchString}
        placeholder="Search"
      />
      <SearchInputIcon />
      {!!searchString && (
        <SearchClearIcon title="Clear" onClick={handleClear} />
      )}
    </SearchInputCont>
  );
};

export default Search;

const SearchInputCont = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  &,
  &:focus-visible {
    margin: 0;
    height: 40px;
    width: 256px;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: none !important;
    outline: none !important;
    background-color: white;
    color: black;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &::placeholder {
      color: #aaa;
    }
  }
`;

const SearchInputIcon = styled.img.attrs({ src: "/images/search-icon.svg" })`
  position: absolute;
  top: 13px;
  left: 10px;
  width: 13px;
  height: 13px;
  opacity: 0.5;
`;

const SearchClearIcon = styled.img.attrs({
  src: "/close-icon.png",
  alt: "close",
  width: 12,
})`
  position: absolute;
  top: 13px;
  right: 10px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;
