import React, { useState } from "react";

import Header from "./Header";
import Body from "./Body";

import { Table } from "./styles";

export const sortData = (data, sortField, sortOrder) => {
  if (!sortField) return data;

  return [...data].sort((a, b) => {
    const aValue = a[sortField];
    const bValue = b[sortField];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return sortOrder === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === "boolean" && typeof bValue === "boolean") {
      return sortOrder === "asc"
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    }

    return sortOrder === "asc"
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });
};

function TableContent({ isLoading, tableColumnNames, emails }) {
  const [sort, setSort] = useState({ sort: null, order: null });

  const withSort = emails.length > 1;
  const sortedEmails = withSort
    ? sortData(emails, sort.sort, sort.order)
    : emails;

  const handleClickSort = (sortField) => {
    setSort((curSort) => {
      if (sortField === curSort.sort) {
        if (curSort.order === "asc") {
          return { ...curSort, order: "desc" };
        }
        return { sort: null, order: null };
      }
      return { sort: sortField, order: "asc" };
    });
  };

  return (
    <Table>
      <Header
        tableColumnNames={tableColumnNames}
        onSort={handleClickSort}
        sort={sort}
        withSort={withSort}
      />
      <Body isLoading={isLoading} emails={sortedEmails} />
    </Table>
  );
}

export default TableContent;
