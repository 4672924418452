import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import CustomRoutes from "./CustomRoutes";
import {
  Campaigns,
  Settings,
  Loading,
  Alert,
  Support,
  Reports,
  Login,
  ContactUs,
  Register,
  Subscription,
  CreateCampaign,
  CreateIntegrations,
  AutomateO365KeyGen,
  AutomateHubspotAPI,
  PostSignUpOnboarding,
  MinimizedCampaignPrep,
  InstallHubspotApp,
  Verification,
} from "./pages";
import { CRMS, LOGIN_PAGE_CONTENT_LIST } from "./utils/constants";
import AutomateGsuiteKeyGen from "./pages/AutomateGsuiteKeyGen";
import MainContentLayout from "./components/MainContentLayout";
import { saveDataToLocalStorage } from "./api/localStorage";

const MainApp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const postMessageEventHandler = (event) => {
    if (!event?.data?.iframeStorageData) {
      if (window.location.pathname === "/install-hubspot-app") {
        window.removeEventListener("message", postMessageEventHandler);
        setIsLoading(false);
      }
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);
    for (const key in event.data.iframeStorageData) {
      saveDataToLocalStorage(key, event.data.iframeStorageData[key], true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener("message", postMessageEventHandler);
  }, []);

  return (
    <>
      {!isLoading && (
        <CustomRoutes>
          <Route path="install-hubspot-app" element={<InstallHubspotApp />} />
          <Route path="/" element={<MainContentLayout />}>
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="settings" element={<Settings />} />
            <Route path="reports" element={<Reports />} />
            <Route path="support" element={<Support />} />
          </Route>
          <Route path="loading" element={<Loading />} />
          <Route path="alert" element={<Alert />} />
          <Route
            path="login"
            element={<Login loginPageContent={LOGIN_PAGE_CONTENT_LIST.LOGIN} />}
          />
          <Route path="verification" element={<Verification />} />
          <Route
            path="ask-reset-password"
            element={
              <Login
                loginPageContent={LOGIN_PAGE_CONTENT_LIST.ASK_RESET_PASSWORD}
              />
            }
          />
          <Route
            path="reset-password"
            element={
              <Login
                loginPageContent={LOGIN_PAGE_CONTENT_LIST.RESET_PASSWORD}
              />
            }
          />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="register" element={<Register />} />
          <Route path="subscription-plans" element={<Subscription />} />
          <Route
            path="create-campaign-bullhorn"
            element={<CreateCampaign crm={CRMS.BULLHORN} />}
          />
          <Route
            path="create-campaign-hubspot"
            element={<CreateCampaign crm={CRMS.HUBSPOT} />}
          />
          <Route
            path="create-campaign-google-sheets"
            element={<CreateCampaign crm={CRMS.GOOGLE_SHEETS} />}
          />
          <Route
            path="create-campaign-excel-online"
            element={<CreateCampaign crm={CRMS.EXCEL_ONLINE} />}
          />
          <Route
            path="create-campaign-nationbuilder"
            element={<CreateCampaign crm={CRMS.NATIONBUILDER} />}
          />
          <Route path="create-integrations" element={<CreateIntegrations />} />
          <Route
            path="automate-o365-key-gen"
            element={<AutomateO365KeyGen />}
          />
          <Route path="automate-hubspot-api" element={<AutomateHubspotAPI />} />
          <Route
            path="automate-gsuite-key-gen"
            element={<AutomateGsuiteKeyGen />}
          />
          <Route
            path="post-sign-up-onboarding"
            element={<PostSignUpOnboarding />}
          />
          <Route
            path="min-campaigns-prep"
            element={<MinimizedCampaignPrep />}
          />
        </CustomRoutes>
      )}
    </>
  );
};

export default MainApp;
