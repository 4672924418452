import {
  SimpleConnect,
  Users,
  Companies,
  Campaigns,
  BlogPosts,
  AutoWarm,
  MegaPage,
} from "../../../components/Settings/TabContent";

export const getSidebarConfig = ({
  activeTabsPath,
  fetchIntegrations,
  openCampaignsWithFilter,
  filter,
}) => ({
  admin: {
    title: "Admin",
    tabs: {
      simpleConnect: {
        title: "Simple Connect",
        component: () => (
          <SimpleConnect
            onReloadIntegrations={() => fetchIntegrations(activeTabsPath)}
          />
        ),
      },
      users: {
        title: "Users",
        component: () => (
          <Users openCampaignsWithFilter={openCampaignsWithFilter} />
        ),
      },
      companies: {
        title: "Companies",
        component: () => (
          <Companies openCampaignsWithFilter={openCampaignsWithFilter} />
        ),
      },
      campaigns: {
        title: "Campaigns",
        component: () => (
          <Campaigns companyId={filter.companyId} userId={filter.userId} />
        ),
      },
      blogPosts: {
        title: "Blog Posts",
        component: () => <BlogPosts />,
      },
      autoWarm: {
        title: "AutoWarm",
        component: () => <AutoWarm />,
      },
      seo: {
        title: "SEO",
        tabs: {
          megaPage: {
            title: "Mega Page",
            component: () => <MegaPage />,
          },
        },
      },
    },
  },
});
