/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import StepButton from "../../StepButton";
import {
  getFilters,
  generateCampaignPreview,
} from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { getCrmCampaignTypes } from "../utils";
import { FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS } from "../../../utils/constants";
import Loader from "../../Loader";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import {
  ButtonGroup,
  InputTextarea,
  InputTextareaBlock,
  OFFSET_LEFT,
  OFFSET_LEFT_BTN_GR,
  Section,
} from "../styles";
import SaveDraftButton from "./SaveDraftButton";
import {
  isUserInFirstTimeCampaignType,
  updateFirstTimeCampaignTypeForUser,
} from "../../../utils/utils";
import {
  NATIONBUILDER_CAMPAIGN_TYPES,
  OTHER_DETAILS_PREVIEW_TEXT_TYPE,
  QUILL_SETTINGS_FOR_DETAILS,
} from "../constants";

const FIELDS = {
  [NATIONBUILDER_CAMPAIGN_TYPES.promoteAnEvent]: [
    { label: "Event Name", value: "", isInput: true },
    { label: "Event Time", value: "", isInput: true },
    { label: "Venue Name", value: "", isInput: true, isOptional: true },
    { label: "Event Description", value: "" },
  ],
  [NATIONBUILDER_CAMPAIGN_TYPES.askForDonations]: [
    { label: "Donation drive description", value: "" },
  ],
  [NATIONBUILDER_CAMPAIGN_TYPES.engageWithConstituents]: [
    { label: "What do you want to say?", value: "", isTextarea: true },
  ],
  [NATIONBUILDER_CAMPAIGN_TYPES.turnOutTheVote]: [
    { label: "Message Details", value: "", isTextarea: true },
  ],
  [NATIONBUILDER_CAMPAIGN_TYPES.runQualitativeAnalysis]: [
    { label: "What would you like to ask?", value: "", isTextarea: true },
  ],
};

function CampaignDetailsForNB({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const previewTextType = getCrmCampaignTypes(campaign.crmType)?.includes(
    campaign.type
  )
    ? campaign.type
    : OTHER_DETAILS_PREVIEW_TEXT_TYPE;

  const parentRef = useRef(null);
  const detailsRef = useRef(null);

  const [details, setDetails] = useState(campaign.details);
  const [detailsData, setDetailsData] = useState(
    (campaign.detailsData || FIELDS[campaign.type])?.map((f) => ({ ...f }))
  );
  const [isLoadingDesc, setIsLoadingDesc] = useState(
    !campaign.detailsData &&
      !!campaign.linkData?.editPageUrl &&
      (campaign.type === NATIONBUILDER_CAMPAIGN_TYPES.promoteAnEvent ||
        campaign.type === NATIONBUILDER_CAMPAIGN_TYPES.askForDonations)
  );
  const [isPreviewTextLoading, setIsPreviewTextLoading] = useState(
    previewTextType === OTHER_DETAILS_PREVIEW_TEXT_TYPE &&
      !campaign.detailsPreview?.options
  );
  const [previewText, setPreviewText] = useState(
    campaign.detailsPreview?.options?.[previewTextType]?.replace(/\\n/g, "")
  );
  const [isOpenedHint, setIsOpenedHint] = useState(
    !isUserInFirstTimeCampaignType(previewTextType)
  );
  const [isGenerating, setIsGenerating] = useState(false);

  const isDisabledNext = detailsData
    ? detailsData.some((item) => !item.isOptional && !item.value.trim())
    : !details;

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignDetails,
    parentRef,
  };

  useEffect(() => {
    if (isPreviewTextLoading) {
      const getConfig = async () => {
        const filtersData = await getFilters({
          filter: ["campaignDetails"],
        });
        const options = filtersData.result?.campaignDetails;
        setPreviewText(options?.[previewTextType]?.replace(/\\n/g, ""));
        setCampaign((campaign) => ({
          ...campaign,
          detailsPreview: { options },
        }));
        setIsPreviewTextLoading(false);
      };
      getConfig();
    }
  }, [isPreviewTextLoading]);

  const postMessageEventHandler = (event) => {
    const nationBuilderPage = event?.data?.nationBuilderPage;

    if (!nationBuilderPage) {
      return;
    }

    window.removeEventListener("message", postMessageEventHandler);

    const items = [...detailsData];

    items.at(-1).value = nationBuilderPage.desc || "";

    if (campaign.type === NATIONBUILDER_CAMPAIGN_TYPES.promoteAnEvent) {
      items[2].value = nationBuilderPage.venue || "";
      items[1].value = campaign.linkData.when || "";
      items[0].value = campaign.linkData.name || "";
    }

    setDetailsData(items);

    setIsLoadingDesc(false);
  };

  useEffect(() => {
    if (isLoadingDesc) {
      if (campaign.type === NATIONBUILDER_CAMPAIGN_TYPES.promoteAnEvent) {
        const items = [...detailsData];
        items[1].value = campaign.linkData.when || "";
        items[0].value = campaign.linkData.name || "";
        setDetailsData(items);
      }

      window.addEventListener("message", postMessageEventHandler);

      sendMessageToExtension({
        message: "get-nationbuilder-page",
        data: {
          editPageUrl: campaign.linkData.editPageUrl,
        },
      });
    }
  }, [isLoadingDesc]);

  const isChangedData = () => {
    return (
      JSON.stringify(campaign.detailsData || []) !==
      JSON.stringify(detailsData || [])
    );
  };

  const isChanged = (generatedDetails) => {
    return campaign.details !== (generatedDetails || details);
  };

  const applyCampaignChanges = (asDraft) => {
    setCampaign((campaign) => ({
      ...campaign,
      detailsData,
      details,
      jobDescriptionChanged: isChanged(),
      asDraft,
    }));
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    applyCampaignChanges();
    handleDecreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();
    applyCampaignChanges(true);
  };

  const handleClickNext = async (event) => {
    event.preventDefault();

    let generatedDetails;

    if (detailsData && isChangedData()) {
      generatedDetails = await handleGenerateNow();
      if (!generatedDetails) {
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      detailsData,
      details: generatedDetails || details,
      jobDescriptionChanged: isChanged(generatedDetails),
    }));

    handleIncreaseStep();
  };

  const handleGenerateNow = async () => {
    const questions = detailsData
      .map((item) => ({
        question: item.label,
        answer: item.value.trim(),
      }))
      .filter((item) => item.answer);

    setIsGenerating(true);

    const res = await generateCampaignPreview({
      questions,
      type: campaign.type,
      crm: campaign.crmType,
    });

    setIsGenerating(false);

    const generatedDetails = res.result?.result;

    if (res.success !== true || !generatedDetails) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.isMoneyInsufficient
            ? "You do not have enough credits to perform this operation"
            : "Unfortunately, we had trouble generating preview",
        },
      });
      return;
    }

    setDetails(generatedDetails);

    return generatedDetails;
  };

  const handleChangeField = (e, idx, isBlur) => {
    const val = e.target.value;

    const items = [...detailsData];
    items[idx].value = isBlur ? val.trim() : val;

    setDetailsData(items);
  };

  const handleChangeQuillField = (value, delta, source, editor, idx) => {
    const items = [...detailsData];
    items[idx].value = handleChangeQuill(value, delta, source, editor);
    setDetailsData(items);
  };

  const handleChangeDetails = (value, delta, source, editor) => {
    setDetails(handleChangeQuill(value, delta, source, editor));
  };

  const handleChangeQuill = (value, delta, source, editor) => {
    const text = editor.getText();

    if (!text?.replace(/\r?\n/g, "").trim()) {
      return "";
    }

    return value;
  };

  return isPreviewTextLoading ? (
    <Loader text="Loading preview..." />
  ) : isLoadingDesc ? (
    <Loader text="Loading description..." />
  ) : (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <Title>Campaign Details</Title>
        {detailsData ? (
          <InputBlock>
            {isGenerating && (
              <Loader text="Generating campaign details..." zIndex={11} />
            )}
            <Label>
              Enter information to generate a description of your campaign
            </Label>
            <QuestionsContainer>
              {detailsData.map((item, idx) => (
                <QuestionBlock
                  key={idx}
                  $flexGrow1={
                    !item.isTextarea &&
                    !item.isInput &&
                    idx === detailsData.length - 1
                  }
                >
                  <QuestionLabel>{item.label}</QuestionLabel>
                  {item.isTextarea ? (
                    <AnswerArea
                      value={item.value}
                      onChange={(e) => handleChangeField(e, idx)}
                      onBlur={(e) => handleChangeField(e, idx, true)}
                      autoComplete="off"
                    />
                  ) : item.isInput ? (
                    <AnswerInput
                      value={item.value}
                      onChange={(e) => handleChangeField(e, idx)}
                      onBlur={(e) => handleChangeField(e, idx, true)}
                      autoComplete="off"
                    />
                  ) : (
                    <InputTextareaBlock $marginTop="0px">
                      <InputTextarea
                        id={`t-${idx}`}
                        placeholder=""
                        value={item.value}
                        onChange={(v, d, s, e) =>
                          handleChangeQuillField(v, d, s, e, idx)
                        }
                        {...QUILL_SETTINGS_FOR_DETAILS}
                      />
                    </InputTextareaBlock>
                  )}
                </QuestionBlock>
              ))}
            </QuestionsContainer>
          </InputBlock>
        ) : (
          <InputBlock>
            <Label>Enter a detailed description of your campaign</Label>
            <InputTextareaBlock
              $haxHint={!!previewText}
              $isOpenedHint={isOpenedHint}
            >
              <InputTextarea
                ref={detailsRef}
                id="details"
                placeholder={previewText || "Campaign Details"}
                value={details}
                onChange={handleChangeDetails}
                onFocus={() => {
                  if (previewText && isOpenedHint) {
                    updateFirstTimeCampaignTypeForUser(previewTextType);
                    setIsOpenedHint(false);
                  }
                }}
                {...QUILL_SETTINGS_FOR_DETAILS}
              />
              {!!previewText && !isOpenedHint && !details && (
                <ShowHintButton
                  title="View Hint"
                  onClick={(event) => {
                    event?.preventDefault();
                    event?.stopPropagation();
                    setIsOpenedHint(true);
                    try {
                      detailsRef?.current?.blur();
                    } catch {
                      //
                    }
                  }}
                />
              )}
            </InputTextareaBlock>
          </InputBlock>
        )}
      </Section>
      <ButtonGroup>
        <StepButton
          onClick={handleClickBack}
          isNext={false}
          disabled={isLoadingDesc || isPreviewTextLoading || isGenerating}
        />
        <StepButton
          onClick={handleClickNext}
          disabled={
            isDisabledNext ||
            isLoadingDesc ||
            isPreviewTextLoading ||
            isGenerating
          }
          isNext={true}
        />
        <SaveDraftButton
          onClick={handleClickSaveDraft}
          disabled={isLoadingDesc || isPreviewTextLoading || isGenerating}
        />
      </ButtonGroup>
    </>
  );
}

export default CampaignDetailsForNB;

const Title = styled.div`
  padding: 19px ${OFFSET_LEFT} 0;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;

const InputBlock = styled.div`
  padding: 0 ${OFFSET_LEFT_BTN_GR};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Label = styled.label`
  margin: 0 calc(${OFFSET_LEFT} - ${OFFSET_LEFT_BTN_GR});
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
`;

const ShowHintButton = styled.img.attrs({
  src: "/images/hint.svg",
  alt: "hint",
})`
  position: absolute;
  right: 12px;
  top: 57px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

const QuestionsContainer = styled.div`
  margin: 25px calc(${OFFSET_LEFT} - ${OFFSET_LEFT_BTN_GR}) 0;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 74%;
  flex-grow: 1;
  // max-height: calc(100% - 61px);
  // overflow: auto;
`;

const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  ${({ $flexGrow1 }) => ($flexGrow1 ? "flex-grow: 1;" : "")}
`;

const QuestionLabel = styled.div`
  color: #000000;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const AnswerArea = styled.textarea`
  &,
  &:focus-visible {
    margin: 0;
    padding: 7px 10px;
    width: 100%;
    min-height: 100px;
    outline: none;
    resize: none;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    color: #000000;
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
  }
`;

const AnswerInput = styled.input`
  &,
  &:focus-visible {
    margin: 0;
    padding: 7px 10px;
    width: 100%;
    height: 40px;
    outline: none;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    color: #000000;
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
  }
`;
