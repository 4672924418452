import { styled } from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

export const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

export const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
            width: 115px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

export const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

export const SortIcon = styled.img`
  width: 11px;
`;

export const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

export const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
            width: 115px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

export const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;
