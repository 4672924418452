import React from "react";

import EmailRow from "./Row";

import { EmptyTableData, TableBody, TableRow } from "./styles";

function Body({ isLoading, emails }) {
  return (
    <TableBody>
      {!emails.length && !isLoading ? (
        <TableRow>
          <EmptyTableData colSpan={100}>
            No Transactional Emails to show
          </EmptyTableData>
        </TableRow>
      ) : (
        emails.map((item) => (
          <EmailRow key={`${item.sentOn}_${item.readOn}`} content={item} />
        ))
      )}
    </TableBody>
  );
}

export default Body;
